import { useDocumentTemplateStore } from "@/store/documentTemplatesStore";
import { ApiIsIdUniqueFunc } from "@/utils/commonTypes";
import { ref, computed, Ref, ComputedRef } from "vue";

interface InvalidItem {
  name: string;
  message: string;
}

export interface Validator {
  invalidItems: InvalidItem[];
  isFormValid(): boolean;
  isInvalid(objectName: string): boolean;
  getErrorMessage(objectName: string): string;
  clearValidity(objectName: string): void;
  resetValidation(): void;
  addInvalidItem(objectName: string, message: string): void;
  stringRequiredValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string,
    placeholderText?: string
  ): boolean;
  stringFixLengthValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string,
    length: number
  ): boolean;
  numberRequiredValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean;
  objectRequiredValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean;
  dateRequiredValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string
  ): boolean;
  isDateValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean;
  isDateOnlyMinValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string,
    minDate: Date | null | undefined
  ): boolean;
  isDateOnlyMaxValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string,
    maxDate: Date | null | undefined
  ): boolean;
  numberMinValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string,
    minValue: number
  ): boolean;
  uniquenesValidator<T extends number | string>(
    action: ApiIsIdUniqueFunc<T>,
    value: T | null | undefined,
    objectName: string,
    friendlyName: string
  ): Promise<boolean>;
  addIsUniqueResult(
    isUnique: boolean,
    objectName: string,
    friendlyName: string
  ): boolean;
  fileExistsValidator(
    documentNumber: string,
    objectName: string,
    friendlyName: string
  ): Promise<boolean>;
  isEmailValidator(
    objectName: string,
    objectValue: string,
    friendlyName: string
  ): boolean;
  isStrongPasswordValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string
  ): boolean;
  isArrayMiniumSelectionValidator(
    objectName: string,
    objectValue: any[] | null | undefined,
    friendlyName: string,
    minimumCount: number
  ): boolean;
}

export class ValidatorPrototyp implements Validator {
  invalidItems: InvalidItem[] = [];

  errValueRequired = "{ITEM} must not be empty!";
  errValueNotUnique = "Entered {ITEM} is not unique!";
  errNoTemplate = "For {VALUE} as {ITEM} no document template could be found!";
  errNotANumber = "{ITEM} is not a valid number!";
  errNotADate = "{ITEM} is not a valid date!";
  errNumberSmallerMin = "Min value of {ITEM} must be at least {MIN}!";
  errDateSmallerMin = "{ITEM} must be at greater or equal than {MIN}!";
  errDateSmallerMax = "{ITEM} must be at smaller or equal than {MAX}!";
  errStringNotExactLength = "{ITEM} must be exactly {NUMBER} characters long!";
  errInvalidEmail = "Entered {ITEM} is an invalid email address!";
  errPasswordNotStrong =
    "Entered {ITEM} does not meet the password requirements!";
  errTooLessSelected = "At least {MIN} entries at {ITEM} must be selected!";

  pwSpecialCharacters = "!@#$§%^&*";
  strongPwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$§%^&*])(?=.{12,})/;

  constructor() {
    console.log("Constructor call validator");
  }

  isFormValid(): boolean {
    if (
      Array.isArray(this.invalidItems) &&
      this.invalidItems.length > 0
    ) {
      return false;
    }
    return true;
  }

  /**
   * Checks if an item is invalid
   * @param {string} objectName Objectname
   */
  isInvalid(objectName: string): boolean {
// todo
    if (this.invalidItems && this.invalidItems &&
      this.invalidItems.some(
        (invalidItem) => invalidItem.name === objectName
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Selects the error message for an object name
   * @param objectName
   * @returns
   */
  getErrorMessage(objectName: string): string {
    const invalidItem = this.invalidItems.find(
      (invalidItem) => invalidItem.name === objectName
    );

    if (invalidItem) {
      return invalidItem.message;
    }
    return "";
  }

  /**
   * Clears for an object name the entry
   * @param objectName
   */
  clearValidity(objectName: string): void {
    const index = this.invalidItems.findIndex(
      (invalidItem) => invalidItem.name === objectName
    );

    if (index >= 0) {
      this.invalidItems.splice(index, 1);
    }
  }

  /**
   * Clears all invalid items
   */
  resetValidation(): void {
    this.invalidItems = [];
  }

  /************************************************************
   * Validators
   ************************************************************/
  addInvalidItem(objectName: string, message: string): void {
    // First check if the item has already and entry
    const index = this.invalidItems.findIndex(
      (invalidItem) => invalidItem.name === objectName
    );

    if (index >= 0) {
      this.invalidItems[index].message = this.invalidItems[
        index
      ].message.concat(" ", message);
    } else {
      this.invalidItems.push({ name: objectName, message: message });
    }
  }

  /**
   * Checks if a string value is given
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {String} placeholderText If a placeholder text is given, to avoid accepting the placeholder text as valid value
   */
  stringRequiredValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string,
    placeholderText = ""
  ): boolean {
    if (
      (!objectValue || objectValue === "") &&
       (placeholderText ==="" || objectValue !== placeholderText)
    ) {
      this.addInvalidItem(
        objectName,
        this.errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }

  /**
   * Checks, if the length of a string value matches the expected length
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Number} length The length of the string
   */
  stringFixLengthValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string,
    length: number
  ): boolean {
    if (!objectValue || objectValue.length !== length) {
      this.addInvalidItem(
        objectName,
        this.errStringNotExactLength
          .replace("{ITEM}", friendlyName)
          .replace("{NUMBER}", length.toString())
      );
      return false;
    }
    return true;
  }

  /**
   * Check if a number value is given
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  numberRequiredValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean {
    if (!objectValue) {
      this.addInvalidItem(
        objectName,
        this.errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    } else if (typeof objectValue !== "number") {
      this.addInvalidItem(
        objectName,
        this.errNotANumber.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }

  /**
   * Checks, if an object is not truthy
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  objectRequiredValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean {
    if (!objectValue) {
      this.addInvalidItem(
        objectName,
        this.errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }

  /**
   * Check if a date value is given
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  dateRequiredValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string
  ): boolean {
    if (!objectValue) {
      this.addInvalidItem(
        objectName,
        this.errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return this.isDateValidator(objectName, objectValue, friendlyName);
  }

  /**
   * Checks if a given value is a date
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  isDateValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean {
    if (objectValue && !(objectValue instanceof Date)) {
      this.addInvalidItem(
        objectName,
        this.errNotADate.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }

  /**
   * Checks if a given date is greater or equal a min date
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Date} minDate The minimum date
   */
  isDateOnlyMinValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string,
    minDate: Date | null | undefined
  ): boolean {
    if (
      objectValue &&
      objectValue instanceof Date &&
      minDate &&
      minDate instanceof Date
    ) {
      const objDateOnly = new Date(
        objectValue.getFullYear(),
        objectValue.getMonth(),
        objectValue.getDate()
      );
      const minDateOnly = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate()
      );

      if (objDateOnly < minDateOnly) {
        this.addInvalidItem(
          objectName,
          this.errDateSmallerMin
            .replace("{ITEM}", friendlyName)
            .replace("{MIN}", minDate.toLocaleDateString())
        );
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if a given date is smaller or equal a max date
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Date} maxDate The maximumg date
   */
  isDateOnlyMaxValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string,
    maxDate: Date | null | undefined
  ): boolean {
    if (
      objectValue &&
      objectValue instanceof Date &&
      maxDate &&
      maxDate instanceof Date
    ) {
      const objDateOnly = new Date(
        objectValue.getFullYear(),
        objectValue.getMonth(),
        objectValue.getDate()
      );
      const maxDateOnly = new Date(
        maxDate.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate()
      );

      if (objDateOnly > maxDateOnly) {
        this.addInvalidItem(
          objectName,
          this.errDateSmallerMax
            .replace("{ITEM}", friendlyName)
            .replace("{MAX}", maxDate.toLocaleDateString())
        );
        return false;
      }
      return true;
    } else {
      // TODO?
      return false;
    }
  }

  /**
   * Check if a given value is a number and if it is not smaller then
   * a minimum value
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Number} minValue The min value to validate against
   */
  numberMinValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string,
    minValue: number
  ): boolean {
    if (typeof objectValue !== "number") {
      this.addInvalidItem(
        objectName,
        this.errNotANumber.replace("{ITEM}", friendlyName)
      );
      return false;
    } else if (objectValue < minValue) {
      this.addInvalidItem(
        objectName,
        this.errNumberSmallerMin
          .replace("{ITEM}", friendlyName)
          .replace("{MIN}", minValue.toString())
      );
      return false;
    }
    return true;
  }

  /**
   * Validates a value against the API if it is unique
   * @param {String} action The validation action
   * @param {Object} payload The payload containing the value to be checked
   * @param {String} objectName The item that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  async uniquenesValidator<T extends number | string>(
    action: ApiIsIdUniqueFunc<T>,
    value: T | null | undefined,
    objectName: string,
    friendlyName: string
  ): Promise<boolean> {
    let isUnique = false;

    if (value) {
      try {
        isUnique = await action(value);
      } catch (ex) {
        console.log(ex);
      }

      if (!isUnique) {
        this.addInvalidItem(
          objectName,
          this.errValueNotUnique.replace("{ITEM}", friendlyName)
        );
      }
    }
    return isUnique;
  }

  /**
   * Adds error message if value is not unique
   * @param {Boolean} isUnique The payload containing the value to be checked
   * @param {String} objectName The item that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  addIsUniqueResult(
    isUnique: boolean,
    objectName: string,
    friendlyName: string
  ): boolean {
    if (!isUnique) {
      this.addInvalidItem(
        objectName,
        this.errValueNotUnique.replace("{ITEM}", friendlyName)
      );
      return isUnique;
    }
    return isUnique;
  }

  /**
   * Validates if for a entered document number a document template exists
   * @param {String} documentNumber The document number to be checked
   * @param {String} objectName The item that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  async fileExistsValidator(
    documentNumber: string,
    objectName: string,
    friendlyName: string
  ): Promise<boolean> {
    let fileExists = false;
    const fileTemplateStore = useDocumentTemplateStore();

    try {
      fileExists = await fileTemplateStore.checkIfTemplateExists(
        documentNumber
      );
    } catch (ex) {
      console.log(ex);
    }

    if (!fileExists) {
      this.addInvalidItem(
        objectName,
        this.errNoTemplate
          .replace("{ITEM}", friendlyName)
          .replace("{VALUE}", documentNumber)
      );
    }

    return fileExists;
  }

  /**
   *
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  isEmailValidator(
    objectName: string,
    objectValue: string,
    friendlyName: string
  ): boolean {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        objectValue
      )
    ) {
      return true;
    } else {
      this.addInvalidItem(
        objectName,
        this.errInvalidEmail.replace("{ITEM}", friendlyName)
      );
      return false;
    }
  }

  /**
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  isStrongPasswordValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string
  ): boolean {
    if (objectValue && objectValue !== "") {
      if (!objectValue.match(this.strongPwRegex)) {
        this.addInvalidItem(
          objectName,
          this.errPasswordNotStrong.replace("{ITEM}", friendlyName)
        );
        return false;
      }
    }

    return true;
  }

  /**
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Number} minimumCount
   */
  isArrayMiniumSelectionValidator(
    objectName: string,
    objectValue: any[] | null | undefined,
    friendlyName: string,
    minimumCount: number
  ): boolean {
    if (!objectValue || objectValue.length < minimumCount) {
      this.addInvalidItem(
        objectName,
        this.errTooLessSelected
          .replace("{MIN}", minimumCount.toString())
          .replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }
}

export function useValidator() {
  const errValueRequired = "{ITEM} must not be empty!";
  const errValueNotUnique = "Entered {ITEM} is not unique!";
  const errNoTemplate =
    "For {VALUE} as {ITEM} no document template could be found!";
  const errNotANumber = "{ITEM} is not a valid number!";
  const errNotADate = "{ITEM} is not a valid date!";
  const errNumberSmallerMin = "Min value of {ITEM} must be at least {MIN}!";
  const errDateSmallerMin = "{ITEM} must be at greater or equal than {MIN}!";
  const errDateSmallerMax = "{ITEM} must be at smaller or equal than {MAX}!";
  const errStringNotExactLength =
    "{ITEM} must be exactly {NUMBER} characters long!";
  const errInvalidEmail = "Entered {ITEM} is an invalid email address!";
  const errPasswordNotStrong =
    "Entered {ITEM} does not meet the password requirements!";
  const errTooLessSelected =
    "At least {MIN} entries at {ITEM} must be selected!";

  const pwSpecialCharacters = "!@#$§%^&*";
  const strongPwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$§%^&*])(?=.{12,})/;

  /**
   * Array contains the object member names that are
   * invalid
   */
  const invalidItems = ref<InvalidItem[]>([]);

  /**
   * As long as the array contains entries the form is not valid
   */
  const isFormValid = computed(() => {
    if (Array.isArray(invalidItems.value) && invalidItems.value.length > 0) {
      return false;
    }
    return true;
  });

  /**
   * Checks if an item is invalid
   * @param {string} objectName Objectname
   */
  function isInvalid(objectName: string): boolean {
    if (
      invalidItems.value.some((invalidItem) => invalidItem.name === objectName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Selects the error message for an object name
   * @param objectName
   * @returns
   */
  function getErrorMessage(objectName: string): string {
    const invalidItem = invalidItems.value.find(
      (invalidItem) => invalidItem.name === objectName
    );

    if (invalidItem) {
      return invalidItem.message;
    }
    return "";
  }

  /**
   * Clears for an object name the entry
   * @param objectName
   */
  function clearValidity(objectName: string): void {
    const index = invalidItems.value.findIndex(
      (invalidItem) => invalidItem.name === objectName
    );

    if (index >= 0) {
      invalidItems.value.splice(index, 1);
    }
  }

  /**
   * Clears all invalid items
   */
  function resetValidation(): void {
    invalidItems.value = [];
  }

  /************************************************************
   * Validators
   ************************************************************/
  function addInvalidItem(objectName: string, message: string): void {
    // First check if the item has already and entry
    const index = invalidItems.value.findIndex(
      (invalidItem) => invalidItem.name === objectName
    );

    if (index >= 0) {
      invalidItems.value[index].message = invalidItems.value[
        index
      ].message.concat(" ", message);
    } else {
      invalidItems.value.push({ name: objectName, message: message });
    }
  }

  /**
   * Checks if a string value is given
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {String} placeholderText If a placeholder text is given, to avoid accepting the placeholder text as valid value
   */
  function stringRequiredValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string,
    placeholderText = ""
  ): boolean {
    if (
      (!objectValue || objectValue === "") &&
      (placeholderText ==="" || objectValue !== placeholderText)
    ) {
      addInvalidItem(
        objectName,
        errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }

  /**
   * Checks, if the length of a string value matches the expected length
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Number} length The length of the string
   */
  function stringFixLengthValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string,
    length: number
  ): boolean {
    if (!objectValue || objectValue.length !== length) {
      addInvalidItem(
        objectName,
        errStringNotExactLength
          .replace("{ITEM}", friendlyName)
          .replace("{NUMBER}", length.toString())
      );
      return false;
    }
    return true;
  }

  /**
   * Check if a number value is given
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  function numberRequiredValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean {
    if (!objectValue) {
      addInvalidItem(
        objectName,
        errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    } else if (typeof objectValue !== "number") {
      addInvalidItem(objectName, errNotANumber.replace("{ITEM}", friendlyName));
      return false;
    }
    return true;
  }

  /**
   * Checks, if an object is not truthy
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  function objectRequiredValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string
  ): boolean {
    if (!objectValue) {
      addInvalidItem(
        objectName,
        errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }

   /**
   * Checks if a given value is a date
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
    function isDateValidator(
      objectName: string,
      objectValue: any,
      friendlyName: string
    ): boolean {
      if (objectValue && !(objectValue instanceof Date)) {
        addInvalidItem(objectName, errNotADate.replace("{ITEM}", friendlyName));
        return false;
      }
      return true;
    }

  /**
   * Check if a date value is given
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  function dateRequiredValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string
  ): boolean {
    if (!objectValue) {
      addInvalidItem(
        objectName,
        errValueRequired.replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return isDateValidator(objectName, objectValue, friendlyName);
  }

 

  /**
   * Checks if a given date is greater or equal a min date
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Date} minDate The minimum date
   */
  function isDateOnlyMinValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string,
    minDate: Date | null | undefined
  ): boolean {
    if (
      objectValue &&
      objectValue instanceof Date &&
      minDate &&
      minDate instanceof Date
    ) {
      const objDateOnly = new Date(
        objectValue.getFullYear(),
        objectValue.getMonth(),
        objectValue.getDate()
      );
      const minDateOnly = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate()
      );

      if (objDateOnly < minDateOnly) {
        addInvalidItem(
          objectName,
          errDateSmallerMin
            .replace("{ITEM}", friendlyName)
            .replace("{MIN}", minDate.toLocaleDateString())
        );
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if a given date is smaller or equal a max date
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Date} maxDate The maximumg date
   */
  function isDateOnlyMaxValidator(
    objectName: string,
    objectValue: Date | null | undefined,
    friendlyName: string,
    maxDate: Date | null | undefined
  ): boolean {
    if (
      objectValue &&
      objectValue instanceof Date &&
      maxDate &&
      maxDate instanceof Date
    ) {
      const objDateOnly = new Date(
        objectValue.getFullYear(),
        objectValue.getMonth(),
        objectValue.getDate()
      );
      const maxDateOnly = new Date(
        maxDate.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate()
      );

      if (objDateOnly > maxDateOnly) {
        addInvalidItem(
          objectName,
          errDateSmallerMax
            .replace("{ITEM}", friendlyName)
            .replace("{MAX}", maxDate.toLocaleDateString())
        );
        return false;
      }
      return true;
    } else {
      // TODO?
      return false;
    }
  }

  /**
   * Check if a given value is a number and if it is not smaller then
   * a minimum value
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Number} minValue The min value to validate against
   */
  function numberMinValidator(
    objectName: string,
    objectValue: any,
    friendlyName: string,
    minValue: number
  ): boolean {
    if (typeof objectValue !== "number") {
      addInvalidItem(objectName, errNotANumber.replace("{ITEM}", friendlyName));
      return false;
    } else if (objectValue < minValue) {
      addInvalidItem(
        objectName,
        errNumberSmallerMin
          .replace("{ITEM}", friendlyName)
          .replace("{MIN}", minValue.toString())
      );
      return false;
    }
    return true;
  }

  /**
   * Validates a value against the API if it is unique
   * @param {String} action The validation action
   * @param {Object} payload The payload containing the value to be checked
   * @param {String} objectName The item that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  async function uniquenesValidator<T extends number | string>(
    action: ApiIsIdUniqueFunc<T>,
    value: T | null | undefined,
    objectName: string,
    friendlyName: string
  ): Promise<boolean> {
    let isUnique = false;

    if (value) {
      try {
        isUnique = await action(value);
      } catch (ex) {
        console.log(ex);
      }

      if (!isUnique) {
        addInvalidItem(
          objectName,
          errValueNotUnique.replace("{ITEM}", friendlyName)
        );
      }
    }
    return isUnique;
  }

  /**
   * Adds error message if value is not unique
   * @param {Boolean} isUnique The payload containing the value to be checked
   * @param {String} objectName The item that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  function addIsUniqueResult(
    isUnique: boolean,
    objectName: string,
    friendlyName: string
  ): boolean {
    if (!isUnique) {
      addInvalidItem(
        objectName,
        errValueNotUnique.replace("{ITEM}", friendlyName)
      );
      return isUnique;
    }
    return isUnique;
  }

  /**
   * Validates if for a entered document number a document template exists
   * @param {String} documentNumber The document number to be checked
   * @param {String} objectName The item that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  async function fileExistsValidator(
    documentNumber: string,
    objectName: string,
    friendlyName: string
  ): Promise<boolean> {
    let fileExists = false;
    const fileTemplateStore = useDocumentTemplateStore();

    try {
      fileExists = await fileTemplateStore.checkIfTemplateExists(
        documentNumber
      );
    } catch (ex) {
      console.log(ex);
    }

    if (!fileExists) {
      addInvalidItem(
        objectName,
        errNoTemplate
          .replace("{ITEM}", friendlyName)
          .replace("{VALUE}", documentNumber)
      );
    }

    return fileExists;
  }

  /**
   *
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  function isEmailValidator(
    objectName: string,
    objectValue: string,
    friendlyName: string
  ): boolean {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        objectValue
      )
    ) {
      return true;
    } else {
      addInvalidItem(
        objectName,
        errInvalidEmail.replace("{ITEM}", friendlyName)
      );
      return false;
    }
  }

  /**
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   */
  function isStrongPasswordValidator(
    objectName: string,
    objectValue: string | null | undefined,
    friendlyName: string
  ): boolean {
    if (objectValue && objectValue !== "") {
      if (!objectValue.match(strongPwRegex)) {
        addInvalidItem(
          objectName,
          errPasswordNotStrong.replace("{ITEM}", friendlyName)
        );
        return false;
      }
    }

    return true;
  }

  /**
   *
   * @param {String} objectName The item name that is validated
   * @param {Object} objectValue The value that is validated
   * @param {String} friendlyName The item name for the user friendly messages
   * @param {Number} minimumCount
   */
  function isArrayMiniumSelectionValidator(
    objectName: string,
    objectValue: any[] | null | undefined,
    friendlyName: string,
    minimumCount: number
  ): boolean {
    if (!objectValue || objectValue.length < minimumCount) {
      addInvalidItem(
        objectName,
        errTooLessSelected
          .replace("{MIN}", minimumCount.toString())
          .replace("{ITEM}", friendlyName)
      );
      return false;
    }
    return true;
  }

  return {
    invalidItems,
    isFormValid,
    isInvalid,
    resetValidation,
    getErrorMessage,
    clearValidity,
    addInvalidItem,
    objectRequiredValidator,
    uniquenesValidator,
    stringRequiredValidator,
    numberMinValidator,
    numberRequiredValidator,
    stringFixLengthValidator,
    dateRequiredValidator,
    isDateValidator,
    isDateOnlyMinValidator,
    isDateOnlyMaxValidator,
    isEmailValidator,
    isStrongPasswordValidator,
    isArrayMiniumSelectionValidator,
    pwSpecialCharacters,
    strongPwRegex,
    fileExistsValidator,
    addIsUniqueResult,
  };
}
