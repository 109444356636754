import runtimeConfig from "@/utils/runtimeConfigUtils.js";

/**
 * Constructs the mailto value for @radialsg.com addresses
 * @param name The name of the mailto
 * @param email The actual email
 * @returns
 */
export function getMailToHref(name: string, email: string): string {
  return `mailto:"${name}" <${email}${runtimeConfig("DOMAIN_EMAIL")}>`;
}

/**
 * Gets for a date the UTC string
 * @param date The date value
 * @returns
 */
export function getUTCDateString(date: Date): string {
  if (date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    ).toUTCString();
  }
  return date;
}

/**
 * Adds month to a given date
 * @param date The date
 * @param months The amount of mounts to be added
 * @returns New date object
 */
export function addMonthToDate(date: Date, months: number): Date {
  return new Date(date.setMonth(date.getMonth() + months));
}

/**
 * Adds days to a given date
 * @param date The date
 * @param months The amount of days to be added
 * @returns New date object
 */
export function addDaysToDate(date: Date, days: number): Date {
  return new Date(date.setDate(date.getDate() + days));
}

/**
 * Formats a date into yyyy-mm-dd string
 * @param value The date value
 * @returns
 */
export function formatDate(value?: Date): string {
  if (value && value instanceof Date) {
    const day = value.getDate();
    const month = value.getMonth() + 1;
    const year = value.getFullYear();

    return `${year}-${month}-${day}`;
  }
  return "";
}