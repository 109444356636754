/****************************************************************
 * Store definition
 ***************************************************************/
import { defineStore } from "pinia";

import {
  handleErrors, 
  getHeaders,
  getBaseApiUrl
} from "@/utils/APICalls";

import axios from "axios";

const ENDPOINT = "Authenticate";

import {
  isLoggedIn,
  getUsername,
  isAdministrationsManager,
  isIntranetAdminManager,
  isSales,
  isOperationsManager,
  isSalesManager,
  isNewsManager,
  deleteCookies,
  isAccessTokenValid
} from "@/utils/jwtCookiesHandler";


export const useAuthStore = defineStore({
  id: "authStore",

  state: () => ({
    isAuthenticated: false as boolean,
    isSales: false as boolean,
    isRoleSalesManager: false as boolean,
    isOperationsManager: false as boolean,
    isAdministrationsManager: false as boolean,
    isIntranetAdmin: false as boolean,
    isRoleNewsManager: false as boolean,
    username: "" as string,
  }),

  getters: {
    isLoggedIn(): boolean {
      return this.isAuthenticated;
    },

    isCompanyManager(): boolean {
      return this.isSales ||
          this.isOperationsManager ||
          this.isIntranetAdmin ||
          this.isAdministrationsManager ||
          this.isRoleSalesManager;
    },

    isDocumentNumberManager(): boolean {
      return this.isSales  ||
          this.isOperationsManager ||
          this.isIntranetAdmin ||
          this.isAdministrationsManager ||
          this.isRoleSalesManager;
    },

    isProjectManager(): boolean {
      return this.isOperationsManager ||
          this.isIntranetAdmin ||
          this.isAdministrationsManager ||
          this.isRoleSalesManager;
    },

    isSalesOpportunityManager(): boolean {
      return this.isSales || this.isIntranetAdmin || this.isRoleSalesManager;
    },

    isLicenseSubscriptionManager(): boolean {
      return this.isOperationsManager || this.isIntranetAdmin;
    },

    isDocTypDisciplineManager(): boolean {
      return this.isIntranetAdmin;
    },
  
    isProductVersionManager(): boolean {
      return this.isIntranetAdmin;
    },
  
    isUsersManager(): boolean {
      return this.isIntranetAdmin;
    },

    isNewsManager(): boolean {
      return this.isRoleNewsManager ||  this.isIntranetAdmin;
    }
  },

  actions: {
    setLoggedInUser(): void {
      this.isAuthenticated = isLoggedIn();
      this.isSales = isSales();
      this.isOperationsManager = isOperationsManager();
      this.isAdministrationsManager = isAdministrationsManager();
      this.isIntranetAdmin = isIntranetAdminManager();
      this.isRoleSalesManager = isSalesManager();
      this.isRoleNewsManager = isNewsManager();
      this.username = getUsername();
    },

    logoutUser(): void {
      deleteCookies();
      this.isAuthenticated = false;
      this.isSales = false;
      this.isOperationsManager = false;
      this.isAdministrationsManager = false;
      this.isIntranetAdmin = false;
      this.isRoleSalesManager = false;
      this.isRoleNewsManager = false;
      this.username = "";
    },

    isTokenValid(): boolean {
      return isAccessTokenValid();
    },

    async checkAndRefreshingToken(): Promise<void> {
      if(!this.isTokenValid()) {
        await this.refreshingToken();
      }
    },

    /**
     * Sets the antiforgery cookie - to be done before the login call is done.
     * The provided token is for an unauthenticated state
     * @returns 
     */
    async setAntiForgeryCookies(): Promise<void> {
      const url =getBaseApiUrl() + ENDPOINT + "/SetCookies";
  
      return await axios.get(url, { headers: { ...getHeaders() } })
      .then(() =>  {
         return Promise.resolve();
      })
      .catch((error) => {
        console.log(error);
        const err = handleErrors(error);
        throw err;
      });
    },

    /**
     * Sets the antiforgery cookie - to be done before right after login call is done.
     * The provided token is for an authenticated state
     * @returns 
     */
    async setAntiForgeryCookiesAfterAuthentication(): Promise<void> {
      const url =getBaseApiUrl() + ENDPOINT + "/SetCookiesAuthenticated";

      return await axios.get(url, { headers: { ...getHeaders() } })
      .then(() =>  {
         return Promise.resolve();
      })
      .catch((error) => {
        console.log(error);
        const err = handleErrors(error);
        throw err;
      });
    },

    /**
     * Refreshes the JWT token
     * @returns 
     */
    async refreshingToken(): Promise<any> {
      const url =getBaseApiUrl() + ENDPOINT + "/Refresh";
  
      return await axios
        .post(url, null, { headers: { ...getHeaders() } })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          const err = handleErrors(error);
          throw err;
        });
    },

    /**
     * Logins a user
     * @param data Users credentials
     * @returns 
     */
    async login(data: {username: string; password: string}): Promise<void> {  
      const url =getBaseApiUrl() + ENDPOINT + "/Login";
  
      return await axios
        .post(
          url,
          JSON.stringify({
            username: data.username,
            password: data.password,
          }),
          { headers: { ...getHeaders() } }
        )
        .then(() => {
          this.setLoggedInUser();
          return Promise.resolve();
        })
        .catch((error) => {
          console.log(error);
          throw handleErrors(error);
        });
    },

    /**
     * Logsout an user
     */
    async logout(): Promise<void>  {
      const url =getBaseApiUrl() + ENDPOINT + "/Revoke";
  
      return await axios
        .post(url, null, { headers: { ...getHeaders() } })
        .then(() => {
          this.logoutUser();
          return Promise.resolve();
        })
        .catch((error) => {
          handleErrors(error);
        });
    },

    /**
     * Changes the password for a logged in user
     * @param data 
     * @returns 
     */
    async changePassword(data: {currentPassword: string; newPassword: string; username: string}): Promise<void> {
      const pwData = {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword
      };
  
      const url =
     getBaseApiUrl() + ENDPOINT + '/' + data.username + '/ChangePassword';
  
      return await axios
        .post(url, JSON.stringify(pwData), {
          headers: getHeaders(),
        })
        .then(() => {
          return Promise.resolve();
        })
        .catch((error) => {
          const err = handleErrors(error);
          throw err;
        });
    },


    syncStoreWithCookie() {
      this.setLoggedInUser();
    },

    logoutClient() {
      this.logoutUser();
    },


    checkPolicies(data: string): Promise<boolean> {      
      this.setLoggedInUser();

      let result = false;
  
      switch (data) {
        case "isCompanyManager":  
          result = this.isCompanyManager;
          break;
        case "isDocumentNumberManager":
          result = this.isDocumentNumberManager;
          break;
        case "isProjectManager":
          result = this.isProjectManager;
          break;
        case "isSalesOpportunityManager":
          result = this.isSalesOpportunityManager;
          break;
        case "isLicenseSubscriptionManager":
          result = this.isLicenseSubscriptionManager;
          break;
        case "isDocTypDisciplineManager":
          result = this.isDocTypDisciplineManager;
          break;
        case "isProductVersionManager":
          result = this.isProductVersionManager;
          break;          
        case "isNewsManager":
          result = this.isNewsManager;
          break;
        case "isUsersManager":
          result = this.isUsersManager;
          break;
      }

      return Promise.resolve(result);
    },
  },
});
