import { defineStore } from "pinia";

import { handleErrors, getHeaders,
  getBaseApiUrl } from "../utils/APICalls";

import axios from "axios";

const ENDPOINT = "Utils";

export const useUtilsStore = defineStore({
  // name of the store
  id: "utils",

  state: () => ({
    isIpAddressWhitelisted: false,
  }),

  actions: {
    /**
     *
     * @returns
     */
    async getGuid(): Promise<string> {
      const url =getBaseApiUrl() + ENDPOINT + "/GetGuid";

      return await axios
        .get(url, { headers: { ...getHeaders() } })
        .then((response) => {
          return Promise.resolve(response.data.guid);
        })
        .catch((error) => {
          const err = handleErrors(error);
          throw err;
        });
    },
    /**
     *
     * @returns
     */
    async checkIsIpAddressWhitelisted(): Promise<void> {
      const url =
       getBaseApiUrl() +
        ENDPOINT +
        "/CheckIPAllowedForLicenses";

      return await axios
        .get(url, { headers: { ...getHeaders() } })
        .then((response) => {
          this.isIpAddressWhitelisted = response.data;
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          const err = handleErrors(error);
          throw err;
        });
    },
    /**
     * 
     * @param input 
     * @returns 
     */
    async getRandomKey(input: string): Promise<string> {
      const url = `${getBaseApiUrl()}${ENDPOINT}/GetRandom/${input}`;
      return await axios
        .get(url, { headers: { ...getHeaders() } })
        .then((response) => {
          return Promise.resolve(response.data.randomKey);
        })
        .catch((error) => {
          const err = handleErrors(error);
          throw err;
        });
    },
  },
});
