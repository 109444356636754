import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "p-pl-2 p-ml-2 p-mt-0",
  style: {"line-height":"1.5"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Password = _resolveComponent("Password")!

  return (_openBlock(), _createBlock(_component_Password, {
    placeholder: "Password",
    strongRegex: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$§%^&*])(?=.{12,})",
    mediumRegex: "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).",
    toggleMask: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Divider),
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "p-mt-2" }, "Requirements", -1)),
      _createElementVNode("ul", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("li", null, "At least one lowercase", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("li", null, "At least one uppercase", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("li", null, "At least one numeric", -1)),
        _createElementVNode("li", null, "At least one specail character (" + _toDisplayString(_ctx.pwSpecialCharacters) + ")", 1),
        _cache[3] || (_cache[3] = _createElementVNode("li", null, "Minimum 12 characters", -1))
      ])
    ]),
    _: 1
  }))
}