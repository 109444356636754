<template>
  <Password
    placeholder="Password"
    strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$§%^&*])(?=.{12,})"
    mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})."
    toggleMask
  >
    <template #footer>
      <Divider />
      <p class="p-mt-2">Requirements</p>
      <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>At least one specail character ({{ pwSpecialCharacters }})</li>
        <li>Minimum 12 characters</li>
      </ul>
    </template>
  </Password>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Password from "primevue/password";
import Divider from "primevue/divider";

import { useValidator } from "../../../hooks/validations";

export default defineComponent({
  components: {
    Password,
    Divider,
  },
  setup() {
    const { pwSpecialCharacters } = useValidator();

    return {
      pwSpecialCharacters,
    };
  },
});
</script>
