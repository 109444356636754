<template>
    <div class="p-field p-grid p-mb-1 p-ai-center" >
        <div class="p-col-2 p-text-right">
            <slot name="label">
                <label :for="idInput">{{ label }}</label>             
            </slot>
        </div>
        <div class="p-col-10" style="height:100%">
            <slot></slot>
        </div>
        <div class="p-col-10 p-offset-2">
            <slot name="error"></slot>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        idInput: {
            type: String,
            required: false 
        },
        label: {            
            type: String,
            required: false 
        }
    },
})
</script>
