<template>
  <form @submit.prevent="changePassword" autocomplete="off">
    <Message
      v-for="msg of messages"
      :severity="msg.severity"
      :key="msg.id"
      >{{ msg.content }}</Message
    >
    <div class="p-fluid">
      <div class="p-field">
        <label for="current-password">Current Password</label>
        <Password
          id="current-password"
          v-model="data.currentPassword"
          placeholder="Current Password"
          aria-describedby="current-password-help"
          :class="{ 'p-invalid': isInvalid('currentPassword') }"
          @blur="clearValidity('currentPassword')"
          toggleMask
          :feedback="false"
        ></Password>
        <small
          id="current-password-help"
          v-if="isInvalid('currentPassword')"
          class="p-error"
          >{{ getErrorMessage("currentPassword") }}</small
        >
      </div>
    </div>
    <password-confirmation></password-confirmation>
    <div class="p-field">
      <base-button type="submit" label="Change password"></base-button>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref, provide } from "vue";
import PasswordConfirmation from "./PasswordConfirmation.vue";

import { useValidator } from "../../hooks/validations";

import { useRouter } from "vue-router";

import { useToast } from "primevue/usetoast";
import Message from "primevue/message";
import Password from "primevue/password";
import { useAuthStore } from "@/store/authStore";
import { MessageSeverity, UiMessage } from "@/utils/commonTypes";

export default defineComponent({
  components: {
    Password,
    Message,
    PasswordConfirmation,
  },
  emits: ["finished"],
  setup(_, { emit }) {
    const data = ref({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });

    const {
      isFormValid,
      resetValidation,
      clearValidity,
      stringRequiredValidator,
      isStrongPasswordValidator,
      isInvalid,
      getErrorMessage,
      addInvalidItem,
    } = useValidator();

    provide("formData", data);
    provide("clearValidity", clearValidity);
    provide("isInvalid", isInvalid);
    provide("getErrorMessage", getErrorMessage);

    const toast = useToast();

    const authStore = useAuthStore();

    const router = useRouter();

    const messages = ref<UiMessage[]>([]);

    async function changePassword() {
      validateForm();

      messages.value = [];

      if (isFormValid.value) {
        await authStore
          .changePassword({
            username: authStore.username,
            currentPassword: data.value.currentPassword,
            newPassword: data.value.newPassword,
          })
          .then(async () => {
            toast.add({
              severity: "success",
              summary: "Successful changed password",
              detail: `The password was changed successful. Please login again.`,
              life: 4000,
            });

            await authStore.logout().finally(() => router.push("/auth"));

            emit("finished");
          })
          .catch((error) => {
            messages.value.push({
              id: 1,
              severity: MessageSeverity.ERROR,
              content: error.message,
            });
          });
      } else {
        messages.value.push({
          id: 2,
          severity: MessageSeverity.ERROR,
          content: "Please fix the form validation errors.",
        });
      }
    }

    function validateForm() {
      resetValidation();

      stringRequiredValidator(
        "currentPassword",
        data.value.currentPassword,
        "Current Password"
      );
      if (
        stringRequiredValidator(
          "newPassword",
          data.value.newPassword,
          "New Password"
        )
      ) {
        isStrongPasswordValidator(
          "newPassword",
          data.value.newPassword,
          "New Password"
        );
        if (data.value.newPassword !== data.value.confirmNewPassword) {
          addInvalidItem("confirmNewPassword", "Passwords do not match");
        }
      }
    }

    return {
      data,
      isInvalid,
      getErrorMessage,
      clearValidity,
      changePassword,
      messages,
    };
  },
});
</script>

<style>
@import "../../assets/css/error-styles.css";
</style>
