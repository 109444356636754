import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"
import _imports_0 from './../../assets/viewport_ai_logo.png'


const _hoisted_1 = { class: "p-d-flex p-jc-between p-ai-center" }
const _hoisted_2 = { class: "p-mt-1" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_PrimeMenu = _resolveComponent("PrimeMenu")!
  const _component_change_password = _resolveComponent("change-password")!
  const _component_base_dialog = _resolveComponent("base-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createVNode(_component_router_link, { to: "/home" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              alt: "Viewport.ai logo",
              class: "p-ml-5 p-mrl-5",
              src: _imports_0
            }, null, -1)
          ])),
          _: 1
        }),
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/home" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Home")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/projects" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Project")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/companies" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Companies")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/documents" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Documents")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/sales" }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Sales")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/news" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("News")
                  ])),
                  _: 1
                })
              ]),
              (_ctx.ipWhitelisted)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                    _createVNode(_component_router_link, { to: "/subscriptions" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Subscriptions")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.ipWhitelisted)
                ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                    _createVNode(_component_router_link, { to: "/licenses" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Licenses")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.ipWhitelisted && _ctx.isLicenseSubscriptionManager)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createVNode(_component_router_link, { to: "/companiesapikey" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("API Keys")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/productversions" }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Product versions")
                  ])),
                  _: 1
                })
              ]),
              (_ctx.ipWhitelisted && _ctx.isUsersManager)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createVNode(_component_router_link, { to: "/users" }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Users")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoggedIn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_Chip, {
              label: _ctx.username,
              icon: "pi pi-user",
              class: "p-mr-3 custom-chip",
              onClick: _ctx.toggleUserMenu,
              "aria-haspopup": "true",
              "aria-controls": "user_menu",
              style: {"min-width":"100px"}
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_PrimeMenu, {
              id: "user_menu",
              ref: "usermenu",
              model: _ctx.umItems,
              popup: true
            }, null, 8, ["model"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_base_dialog, {
      open: _ctx.showPasswordChangeDialog,
      onClose: _ctx.closeChangePasswordDialog,
      title: "Change your password"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_change_password, { onFinished: _ctx.closeChangePasswordDialog }, null, 8, ["onFinished"])
      ]),
      _: 1
    }, 8, ["open", "onClose"])
  ], 64))
}