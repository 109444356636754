import axios, { AxiosResponse } from "axios";

import runtimeConfig from "@/utils/runtimeConfigUtils.js";

/**
 * The default headers
 */
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Credentials": "true",
};

/**
 * Method to access the header from other files
 */
export function getHeaders() {
  return {
    ...headers, 
  } ;
}

export function getBaseApiUrl(): string {
  return runtimeConfig("VUE_APP_API_BASE_URL");
}

/**
 * Creating a new object. No response body expected
 * @param {String} entity The endpoint for the object that shall be created
 * @param {Object} data The object that shall be created
 */
export async function createObject<T extends object>(entity: string, data: T): Promise<AxiosResponse> {
  const url = getBaseApiUrl() + entity;
  return await axios
    .post(url, JSON.stringify(data), { headers: getHeaders() })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Creating a new object and expecting a response body
 * @param {String} entity The endpoint for the object that shall be created
 * @param {Object} data The object that shall be created
 */
export async function createObjectWithResponseData<T extends object>(entity: string, data: T): Promise<any> {
  const url = getBaseApiUrl() + entity;
  return await axios
    .post(url, JSON.stringify(data), { headers: getHeaders()  })
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data);
      });
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Checks if an ID of a object is unique
 * @param {String} entity The endpoint for the object
 * @param {*} entityId The Id of the object
 */
export async function checkIdIsUnique(entity: string, entityId: string | number): Promise<any> {
  const url =
    getBaseApiUrl() + entity + "/IsIdUnique/" + entityId;
  return await axios
    .get(url, { headers: getHeaders()  })
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data);
      });
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Generic get request
 * @param {String} entity The endpoint for the object
 * @param {String} endpoint The specific endpoint that shall be called
 */
export async function generalGet(entity: string, endpoint: string): Promise<any> {
  const url = getBaseApiUrl() + entity + endpoint;
  return await axios
    .get(url, { headers: getHeaders()  })
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data);
      });
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Calling an endpoint to select many objects
 * @param {String} entity The endpoint of the objects to be loaded
 * @param {String} specialEndpoint Optional - if a specific endpoint shall be called
 */
export async function loadObjects(entity: string, specialEndpoint = ""): Promise<any> {
  const url = getBaseApiUrl() + entity + specialEndpoint;
  return await axios
    .get(url, { headers: getHeaders()  })
    .then((response) => {
      return new Promise((resolve) => {
        if (response.data) {
          resolve(response.data);
        } else if (response) {
          resolve(response);
        } else {
          resolve("");
        }
      });
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Loading an instance of an object by its Id
 * @param {String} entity The endpoint of the object to be loaded
 * @param {*} entityId The id of the object that shall be loaded
 */
export async function loadObject(entity: string, entityId: string | number): Promise<any> {
  const url = getBaseApiUrl() + entity + "/" + entityId;
  return await axios
    .get(url, { headers: getHeaders()  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Deleting on object
 * @param {String} entity The endpoint of the object to be deleted
 * @param {*} entityId The id of the object that shall be deleted
 */
export async function deleteObject(entity: string, entityId: string | number): Promise<any> {
  const url = getBaseApiUrl() + entity + "/" + entityId;
  return await axios
    .delete(url, { headers: getHeaders()  })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Archiving an object
 * @param {String} entity The endpoint of the object to be archived
 * @param {*} entityId The id of the object that shall be archived
 */
export async function archiveObject(entity: string, entityId: string | number): Promise<any> {
  const url =
    getBaseApiUrl() + entity + "/" + entityId + "/Archive";
  return await axios
    .post(url, null, { headers: getHeaders()  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Select the next (new) id of an entity
 * @param {String} entity The endpoint of the object for that a new id shall be selected
 */
export async function selectNewId(entity: string): Promise<any> {
  const url = getBaseApiUrl() + entity + "/GetNextId";
  return await axios
    .get(url, { headers: getHeaders()  })
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data);
      });
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Updating an object
 * @param {String} entity The endpoint of the object to be archived
 * @param {*} entityId The id of the object that shall be updated
 * @param {Object} data The object that shall be updated
 */
export async function updateObject<T extends object>(entity: string, entityId: string | number, data: T): Promise<AxiosResponse> {
  const url = getBaseApiUrl() + entity + "/" + entityId;
  return await axios
    .put(url, JSON.stringify(data), { headers: getHeaders()  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = handleErrors(error);
      throw err;
    });
}

/**
 * Handling an error to provide a more specific error handle
 * @param {loadObject} error
 */
export function handleErrors(error: any) {
  console.log(error);
  if (error.response) {
    const response = error.response;

    if (response.status === 401) {
      console.log("Error 401");
      return error;
    }

    if (response.status === 400 && response.statusText === "Bad Request") {
      console.log("API Validation error occurred");
      console.log(response);
      // Check if it is validation errors
      if (response.data.title && response.data.errors) {
        return new Error(
          response.data.title + " " + JSON.stringify(response.data.errors)
        );
      } else if (
        Array.isArray(response.data) &&
        response.data.length > 0 &&
        response.data[0].code &&
        response.data[0].description
      ) {
        let errorString = "";

        response.data.forEach((element: any) => {
          if (errorString !== "") {
            errorString += "; ";
          }
          errorString += element.description;
        });
        return new Error(errorString);
      } else {
        return new Error(response.data);
      }
    }

    if(response.status === 403) {
      console.log("Not allowed");
      console.log(response.data);
      return new Error("Request is not allowed.");
    }

    // Not found
    if (response.status === 404) {
      console.log("Resource could not be found");
      console.log(response.data);
      return new Error(`Resource could not be found.`);
    }

    if (response.status === 500) {
      console.log("An internal server error occurred");
      console.log(response.data);

      if (typeof response.data !== "object") {
        return new Error(response.statusText + ": " + response.data);
      } else {
        return new Error(
          response.statusText + ": " + JSON.stringify(response.data)
        );
      }
    }

    console.log("API error occurred");
    console.log(response.data);
    return new Error(response.data || "API error occurred.");
  } else {
    return new Error(error);
  }
}
