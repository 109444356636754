<template>
  <div class="p-d-flex p-jc-between p-ai-center">
    <nav class="p-mt-1">
      <router-link to="/home">
        <img
          alt="Viewport.ai logo"
          class="p-ml-5 p-mrl-5 "
          src="./../../assets/viewport_ai_logo.png"
        />
      </router-link>
      <ul v-if="isLoggedIn">
        <li>
          <router-link to="/home">Home</router-link>
        </li>
        <li>
          <router-link to="/projects">Project</router-link>
        </li>
        <li>
          <router-link to="/companies">Companies</router-link>
        </li>
        <li>
          <router-link to="/documents">Documents</router-link>
        </li>
        <li>
          <router-link to="/sales">Sales</router-link>
        </li>
        <li>
          <router-link to="/news">News</router-link>
        </li>
        <li v-if="ipWhitelisted">
          <router-link to="/subscriptions">Subscriptions</router-link>
        </li>
        <li v-if="ipWhitelisted">
          <router-link to="/licenses">Licenses</router-link>
        </li>
        <li v-if="ipWhitelisted && isLicenseSubscriptionManager">
          <router-link to="/companiesapikey">API Keys</router-link>
        </li>
        <li>
          <router-link to="/productversions">Product versions</router-link>
        </li>
        <!-- <li>
          <router-link to="/machines">Machines</router-link>
        </li> -->
        <li v-if="ipWhitelisted && isUsersManager">
          <router-link to="/users">Users</router-link>
        </li>
      </ul>
    </nav>
    <div v-if="isLoggedIn">
      <Chip
        :label="username"
        icon="pi pi-user"
        class="p-mr-3 custom-chip"
        @click="toggleUserMenu"
        aria-haspopup="true"
        aria-controls="user_menu"
        style="min-width: 100px"
      />

      <PrimeMenu id="user_menu" ref="usermenu" :model="umItems" :popup="true" />
    </div>
  </div>

  <base-dialog
    :open="showPasswordChangeDialog"
    @close="closeChangePasswordDialog"
    title="Change your password"
  >
    <template #default>
      <change-password @finished="closeChangePasswordDialog"></change-password>
    </template>
  </base-dialog>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import Chip from "primevue/chip";
import PrimeMenu from "primevue/menu";
import ChangePassword from "../user/ChangePassword.vue";
import useRoles from "../../hooks/roles";
import { useAuthStore } from "@/store/authStore";
import { useUtilsStore } from "@/store/mainStore";

export default defineComponent({
  components: { Chip, PrimeMenu, ChangePassword },
  setup() {
    const authStore = useAuthStore();
    const utilsStore = useUtilsStore();

    // Status, if user is logged in
    const isLoggedIn = computed(() => authStore.isLoggedIn);

    // Watch if loggin-status changes - somewhere it is not reactive - not yet sure where
    watch(
      () => isLoggedIn.value,
      (newValue, oldValue) => {
        console.log(
          `isLoggedIn.Value ${isLoggedIn.value}, new value ${newValue}, old value ${oldValue}.`
        );
      }
    );

    // the username of the logged in user
    const username = computed(() => authStore.username);

    // flag, if the remote ip address is whitelisted
    const ipWhitelisted = computed(() => utilsStore.isIpAddressWhitelisted);

    /**
     * Checks if the remote ip address is whitelisted
     */
    function checkIpWhitelisted() {
      if (isLoggedIn.value) {
        utilsStore.checkIsIpAddressWhitelisted().catch((error) => {
          console.log(error);
        });
      }
    }

    // Calling the check
    checkIpWhitelisted();

    const router = useRouter();

    /**
     * Sign out function
     */
    async function signOut() {
      // calling thte signout action
      await authStore.logout().then(() => router.push("/auth"));
    }

    /******************************************************************
     * User PrimeMenu
     ******************************************************************/
    // The menu compnents
    const usermenu = ref<PrimeMenu | null>(null);

    // The menu items
    const umItems = [
      {
        label: "Change Password",
        icon: "pi pi-key",
        command: () => openPasswordChangeDialog(),
      },
      { label: "Logout", icon: "pi pi-sign-out", command: () => signOut() },
    ];

    /**
     * Toggle the user menus
     */
    function toggleUserMenu(event: Event) {
      if (usermenu.value) {
        usermenu.value.toggle(event);
      }
    }

    /**
     * Change password
     */
    const showPasswordChangeDialog = ref(false);

    /**
     * Opens the change password dialog
     */
    function openPasswordChangeDialog() {
      showPasswordChangeDialog.value = true;
    }

    /**
     * Closes the change password dialog
     */
    function closeChangePasswordDialog() {
      showPasswordChangeDialog.value = false;
    }

    /**
     * PrimeMenu item User is shown if user is user manager
     * PrimeMenu item CompanyAPIKey is shown if user is lic. subscription manager
     */
    const { isLicenseSubscriptionManager, isUsersManager } = useRoles();

    return {
      isLoggedIn,
      username,
      signOut,
      ipWhitelisted,
      usermenu,
      umItems,
      toggleUserMenu,
      showPasswordChangeDialog,
      openPasswordChangeDialog,
      closeChangePasswordDialog,
      isLicenseSubscriptionManager,
      isUsersManager,
    };
  },
});
</script>

<style scoped>
nav {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: sofia-pro, sans-serif, Helvetica Neue, Helvetica, Arial;
  font-size: 1rem;
  line-height: 1.875rem;
}

ul {
  list-style: none;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  padding: 0;
}

li {
  margin: 0 1rem;
}

img {
  width: 170px;
}

a {
  text-decoration: none;
  font: inherit;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  display: inline-block;
}

a:hover,
a.active {
  color: #002842;
}

.p-chip.custom-chip {
  background: #f38800;
  border-color: #ffffff;
  color: #ffffff;
}
</style>
