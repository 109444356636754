import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = {
  class: "p-field",
  style: { overflow: 'visible' }
}
const _hoisted_3 = {
  key: 0,
  id: "new-password-help",
  class: "p-error"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = {
  class: "p-field",
  style: { overflow: 'visible' }
}
const _hoisted_6 = {
  key: 0,
  id: "confirm-new-password-help",
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_password = _resolveComponent("base-password")!
  const _component_Password = _resolveComponent("Password")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { for: "password" }, "New Password", -1)),
        _createVNode(_component_base_password, {
          id: "new-password",
          modelValue: _ctx.data.newPassword,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.newPassword) = $event)),
          placeholder: "New password",
          "aria-describedby": "new-password-help",
          class: _normalizeClass({ 'p-invalid': _ctx.isInvalid('newPassword') }),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearValidity('newPassword'))),
          autocomplete: "new-password"
        }, null, 8, ["modelValue", "class"]),
        (_ctx.isInvalid('newPassword'))
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.getErrorMessage("newPassword")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { for: "password" }, "Confirm new password", -1)),
        _createVNode(_component_Password, {
          id: "confirm-new-password",
          modelValue: _ctx.data.confirmNewPassword,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.confirmNewPassword) = $event)),
          placeholder: "Confirm new Password",
          toggleMask: "",
          feedback: false,
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearValidity('confirmNewPassword'))),
          inputClass: _ctx.confirmedPasswordClass,
          class: _normalizeClass({ 'p-invalid': _ctx.isInvalid('confirmNewPassword') }),
          autocomplete: "new-password"
        }, null, 8, ["modelValue", "inputClass", "class"]),
        (_ctx.isInvalid('confirmNewPassword'))
          ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.getErrorMessage("confirmNewPassword")), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}