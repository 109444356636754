<template>
    <div class="p-fluid">
      <div class="p-field" :style="{ overflow: 'visible' }">
        <label for="password">New Password</label>
        <base-password
          id="new-password"
          v-model="data.newPassword"
          placeholder="New password"
          aria-describedby="new-password-help"
          :class="{ 'p-invalid': isInvalid('newPassword') }"
          @blur="clearValidity('newPassword')"          
          autocomplete="new-password"
        ></base-password>
        <small
          id="new-password-help"
          v-if="isInvalid('newPassword')"
          class="p-error"
          >{{ getErrorMessage("newPassword") }}</small
        >
      </div>
    </div>
    <div class="p-fluid">
      <div class="p-field" :style="{ overflow: 'visible' }">
        <label for="password">Confirm new password</label>
        <Password
          id="confirm-new-password"
          v-model="data.confirmNewPassword"
          placeholder="Confirm new Password"
          toggleMask
          :feedback="false" 
          @blur="clearValidity('confirmNewPassword')"          
          :inputClass="confirmedPasswordClass"          
          :class="{ 'p-invalid': isInvalid('confirmNewPassword') }"
          autocomplete="new-password"
        ></Password>
        <small
          id="confirm-new-password-help"
          v-if="isInvalid('confirmNewPassword')"
          class="p-error"
          >{{ getErrorMessage("confirmNewPassword") }}</small
        >
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, inject  } from 'vue'
import BasePassword from "../base/form/BasePassword.vue";

import Password from "primevue/password";

export default defineComponent({
   components: {
    BasePassword,
    Password,
  },
  setup() {
    const data: any = inject('formData');
    const clearValidity = inject('clearValidity');
    const isInvalid = inject('isInvalid');
    const getErrorMessage = inject('getErrorMessage');

    const confirmedPasswordClass = computed(() => {
        if(data.value.newPassword === data.value.confirmNewPassword) {
            return 'valid-border-background'
        }
        // only if both input fields contains data
        if(data.value.newPassword !== '' &&  data.value.confirmNewPassword !== '' ) {
           return 'p-invalid invalid-background';
        }
       return '';
     });


    return {
      data,
      isInvalid,
      getErrorMessage,
      clearValidity,
      confirmedPasswordClass
    };
  },
})
</script>

<style>
@import  "../../assets/css/error-styles.css";
</style>
