<template>
  <Carousel
    :value="newsList"
    :numVisible="1"
    :numScroll="1"
    orientation="vertical"
    verticalViewPortHeight="580px"
  >
    <template #item="slotProps">
    <div :key="slotProps.data.id" class="p-p-2">
      <news-card
        :author="slotProps.data.author"
        :category="slotProps.data.category"
        :title="slotProps.data.title"
        :content="slotProps.data.content"
        :thumbnailUrl="slotProps.data.thumbnailUrl"
        :thumbnailText="slotProps.data.thumbailText"
        :unpublishDate="slotProps.data.unpublishDate"
        :publishDate="slotProps.data.publishDate"
        :link="slotProps.data.link"
        :linkText="slotProps.data.linkText"
      />
    </div>
    </template>
  </Carousel>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import Carousel from "primevue/carousel";
import NewsCard from "./NewsCard.vue";
import { useNewsStore } from "@/store/newsStore";
import useItemsLoader from "@/hooks/itemsLoader";

export default defineComponent({
  components: {
    Carousel,
    NewsCard,
  },
  setup() {
    const newsStore = useNewsStore();

    const { items, loadItems } = useItemsLoader(
      newsStore.loadCurrentNews,
      computed(() => newsStore.currentNews)
    );

    loadItems();

    return {
      newsList: items,
    };
  },
});
</script>

