import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = {
  key: 0,
  id: "current-password-help",
  class: "p-error"
}
const _hoisted_4 = { class: "p-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Password = _resolveComponent("Password")!
  const _component_password_confirmation = _resolveComponent("password-confirmation")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)), ["prevent"])),
    autocomplete: "off"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (msg) => {
      return (_openBlock(), _createBlock(_component_Message, {
        severity: msg.severity,
        key: msg.id
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(msg.content), 1)
        ]),
        _: 2
      }, 1032, ["severity"]))
    }), 128)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { for: "current-password" }, "Current Password", -1)),
        _createVNode(_component_Password, {
          id: "current-password",
          modelValue: _ctx.data.currentPassword,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.currentPassword) = $event)),
          placeholder: "Current Password",
          "aria-describedby": "current-password-help",
          class: _normalizeClass({ 'p-invalid': _ctx.isInvalid('currentPassword') }),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearValidity('currentPassword'))),
          toggleMask: "",
          feedback: false
        }, null, 8, ["modelValue", "class"]),
        (_ctx.isInvalid('currentPassword'))
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.getErrorMessage("currentPassword")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_password_confirmation),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_button, {
        type: "submit",
        label: "Change password"
      })
    ])
  ], 32))
}