import { defineStore } from "pinia";

import { getHeaders, handleErrors, getBaseApiUrl } from "@/utils/APICalls";

import axios from "axios";

const ENDPOINT = "FileTemplate";

/****************************************************************
 * Type Definition
 ****************************************************************/
export interface DocumentTemplate {
  id?: number;
  documentNumber: string;
  documentTitle: string;
  documentTypeId: string;
}

/****************************************************************
 * Store definition
 ***************************************************************/
export const useDocumentTemplateStore = defineStore({
  id: "documentTemplate",

  actions: {
    /**
     *
     * @param documentNumber
     * @returns
     */
    async checkIfTemplateExists(documentNumber: string): Promise<boolean> {
      const url = `${getBaseApiUrl()}${ENDPOINT}/CheckTemplateExists`;
      return await axios
        .get(url, {
          headers: getHeaders(),
          params: { filepath: documentNumber },
        })
        .then((response) => {
          return new Promise<boolean>((resolve) => {
            resolve(response.data);
          });
        })
        .catch((error) => {
          const err = handleErrors(error);
          throw err;
        });
    },
    /**
     *
     * @param data
     * @returns
     */
    async downloadFile(data: {
      templateDocumentNumber: string;
      documentTitle: string;
      documentNumber: string;
    }): Promise<{ blobData: Blob; filename: string }> {
      const url = `${getBaseApiUrl()}${ENDPOINT}/GetTemplate`;
      return await axios
        .get(url, {
          headers: getHeaders(),
          params: {
            templateDocnumber: data.templateDocumentNumber,
            documentTitle: data.documentTitle,
            documentNumber: data.documentNumber,
          },
          responseType: "blob",
        })
        .then((response) => {
          // Check the header if a filename is provided
          let filename = "";
          try {
            filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            filename = filename.replaceAll('"', "");
          } catch (error) {
            console.error(error);
          }
          return new Promise<{ blobData: Blob; filename: string }>(
            (resolve) => {
              resolve({ blobData: response.data, filename: filename });
            }
          );
        })
        .catch((error) => {
          const err = handleErrors(error);
          throw err;
        });
    },
  },
});

class DocumentTemplatePrototype implements DocumentTemplate {
  id?: number;
  documentNumber: string;
  documentTitle: string;
  documentTypeId: string;

  constructor(
    documentNumber: string,
    documentTitle: string,
    documentTypeId: string,
    id?: number
  ) {
    this.id = id;
    this.documentNumber = documentNumber;
    this.documentTitle = documentTitle;
    this.documentTypeId = documentTypeId;
  }
}

export function getEmptyDocumentTemplate(): DocumentTemplate {
  return new DocumentTemplatePrototype("", "", "");
}

export function apiToObject(apiData: any): DocumentTemplate {
  return new DocumentTemplatePrototype(
    apiData.documentNumber,
    apiData.documentTitle,
    apiData.documentTypeId,
    apiData.id
  );
}
