import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_news_card = _resolveComponent("news-card")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createBlock(_component_Carousel, {
    value: _ctx.newsList,
    numVisible: 1,
    numScroll: 1,
    orientation: "vertical",
    verticalViewPortHeight: "580px"
  }, {
    item: _withCtx((slotProps) => [
      (_openBlock(), _createElementBlock("div", {
        key: slotProps.data.id,
        class: "p-p-2"
      }, [
        _createVNode(_component_news_card, {
          author: slotProps.data.author,
          category: slotProps.data.category,
          title: slotProps.data.title,
          content: slotProps.data.content,
          thumbnailUrl: slotProps.data.thumbnailUrl,
          thumbnailText: slotProps.data.thumbailText,
          unpublishDate: slotProps.data.unpublishDate,
          publishDate: slotProps.data.publishDate,
          link: slotProps.data.link,
          linkText: slotProps.data.linkText
        }, null, 8, ["author", "category", "title", "content", "thumbnailUrl", "thumbnailText", "unpublishDate", "publishDate", "link", "linkText"])
      ]))
    ]),
    _: 1
  }, 8, ["value"]))
}