import { createApp, defineAsyncComponent } from "vue";

import App from "./App.vue";

/*********************************************************************
 * Importing global components
 *********************************************************************/
import BaseCard from "./components/base/BaseCard.vue";
import BaseCardBig from "./components/base/BaseCardBig.vue";
import BaseCardFullPage from "./components/base/BaseCardFullPage.vue";
import BaseButton from "./components/base/BaseButton.vue";
import BaseFormInput from "@/components/base/form/BaseFormInput.vue";
import BaseTwoCards from "@/components/layouts/BaseTwoCardLayout.vue";
import BaseFormLineOnlyInput from "@/components/base/form/BaseFormLineOnlyInput.vue";

const BaseDialog = defineAsyncComponent(() =>
  import("./components/base/BaseDialog.vue")
);
const BaseFullScreen = defineAsyncComponent(() =>
  import("./components/base/BaseFullScreen.vue")
);
const BaseSubmenu = defineAsyncComponent(() =>
  import("./components/base/BaseSubmenu.vue")
);
const LoadingSpinner = defineAsyncComponent(() =>
  import("./components/base/BaseLoadingSpinner.vue")
);
const BaseFormLine = defineAsyncComponent(() =>
  import("./components/base/form/BaseFormLine.vue")
);
const BaseConfirmation = defineAsyncComponent(() =>
  import("./components/base/BaseConfirmation.vue")
);
const PrimeDialog = defineAsyncComponent(() => import("primevue/dialog"));

import ItemsTable from '@/components/layouts/ItemsTable.vue';

/*********************************************************************
 * Creating the app
 *********************************************************************/
const app = createApp(App);

/*********************************************************************
 * Importing Privmevue Template and css
 *********************************************************************/
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

/*********************************************************************
 * Importing libs
 *********************************************************************/
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
// @ts-ignore
import router from "./route.js";
import axios from "axios";

// the store
import { createPinia } from 'pinia';
app.use(createPinia());

import {useAuthStore} from '@/store/authStore';

/*********************************************************************
 * Configure axios
 *********************************************************************/
axios.defaults.withCredentials = true; // To send cookies with the request
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; 

/*********************************************************************
 * Defining an interceptor to check if the token is expired and 
 * tries to refresh it
 *********************************************************************/
// register the axios response interceptor
createAxiosResponseInterceptor();

/**
 * Array used to store subscribers of the request, in case of a
 * successful refresh call the original request
 */
// @ts-ignore
const subscribers = [];

/**
 * Registering the axios response interceptor
 * Use as a function to deactivate the interceptor
 * during a refresh call -> avoids multiple refresh call
 */
function createAxiosResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
    (response) => {
      // no error - just forward the response
      return response;
    },
    async (err) => {
      // Check for network error -> API not reachable, no status field available
      if(!err.response || !err.response.status) {
        throw err;
      }

      const {
        config,
        response: { status, headers },
      } = err;

      // get the header value -> the header contains the reason why the
      // JWT token was rejected by the backend
      const authHeader = headers["www-authenticate"];

      // Check if the url is the login url -> then no automatic token refresh!
      if (config.url.includes("Authenticate/Login")) {
        throw err;
      } else {
        // If 401 and expired token then try to refresh
        if (
          status === 401 &&
          authHeader &&
          authHeader.includes("token expired")
        ) {
          // deregister the interceptor to avoid multiple calls
          axios.interceptors.response.eject(interceptor);

          // get the authStore
          const authStore = useAuthStore();
           
          await authStore.refreshingToken()
            .then(() => {
                // back to the origional request
              console.log("refreshed token");
            })
            .catch(async(error: any) => {
              // if an error occurred or the refresh was not possible - logout the client
              console.log("push to auth");
              await authStore.logoutClient();
              router.push("/auth");
              
              return Promise.reject(error);
            })
            .finally(createAxiosResponseInterceptor); // register the interceptor again
          
          const requestSubscribers = new Promise((resolve) => {
            subscribeTokenRefresh(() => {
              resolve(axios(config));
            });
          });

          // call the original request
          onRefresh();

          return requestSubscribers;
        } else if (status === 401) {
          // any other 401 - redirect to auth
          router.push("/auth");
        } else {
          throw err;
        }
      }
    }
  );
}

// @ts-ignore
function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

// @ts-ignore
function onRefresh() {
  // @ts-ignore
  subscribers.map((cb) => cb());
}

/*********************************************************************
 * Defining global components
 *********************************************************************/
app.component("BaseCard", BaseCard);
app.component("BaseButton", BaseButton);
app.component("BaseCardBig", BaseCardBig);
app.component("BaseDialog", BaseDialog);
app.component("BaseFullScreen", BaseFullScreen);
app.component("BaseSubmenu", BaseSubmenu);
app.component("BaseCardFullPage", BaseCardFullPage);
app.component("LoadingSpinner", LoadingSpinner);
app.component("BaseFormLine", BaseFormLine);
app.component("BaseConfirmation", BaseConfirmation);
app.component("PrimeDialog", PrimeDialog);
app.component("ItemsTable", ItemsTable);
app.component("BaseFormInput", BaseFormInput);
app.component("BaseTwoCards" ,BaseTwoCards);
app.component("BaseFormLineOnlyInput", BaseFormLineOnlyInput);


import Tooltip from "primevue/tooltip";
app.directive('tooltip', Tooltip);

app.use(router);
app.use(ToastService);
app.use(PrimeVue);

app.mount("#app");