import { ref, computed, readonly, watch, Ref, ComputedRef } from "vue";

import {
  ApiSelectAllFunc,
  ApiSelectReturnAllFunc,
  BaseApiEntity,
} from "@/utils/commonTypes";

import { useToast } from "primevue/usetoast";

/**
 * Hook to load entities and stores the loaded items in a getter
 * @param {Function} loadAction The load action
 * @param {Array of T} itemGetters The getter name that contains the loaded items
 * @returns isLoading:Boolean - Flag if items are loading
 * @returns items:computed|Array - The loaded items that are stored in a getter
 * @returns loadItems:function - Ref to the load items function
 */
export default function useItemsLoader<
  T extends BaseApiEntity<TId>,
  TId extends string | number
>(loadAction: ApiSelectAllFunc, itemGetters: ComputedRef<T[]>) {
  const toast = useToast();

  const isLoading = ref(false);
  
  const items = itemGetters;

  /**
   * Loads the item by dispatching the load action
   * @param {Boolean} forceReload If true the backend is called anyway. If false, a reload from the
   *    backend will only be done if a minimum time elapsed between the last call.
   */
  async function loadItems(forceReload = false) {
    isLoading.value = true;

    await loadAction(forceReload).catch((error) => {
      toast.add({
        severity: "error",
        summary: "Error loading data",
        detail: `An error occurred while loading data. ${error}`,
        life: 4000,
      });
    });

    isLoading.value = false;
  }

  return { isLoading, loadItems, items };
}

/**
 * Hook to load entities and stores the loaded items in a variable
 * @param {Function} loadAction The load action
 * @returns isLoading:Boolean - Flag if items are loading
 * @returns items:Array - The loaded items
 * @returns loadItems:function - Ref to the load items function
 */
export function useItemsLoadersNoGetter<
  T extends BaseApiEntity<TId>,
  TId extends string | number
>(loadAction: ApiSelectReturnAllFunc<T, TId>) {
  const toast = useToast();

  const isLoading = ref(false);

  const items: Ref<T[]> = ref([]);

  /**
   * Loads the item by dispatching the load action
   * @param {Object} params Additional parameters that can be transferred to the action
   * @param {Boolean} forceReload If true the backend is called anyway. If false, a reload from the
   *    backend will only be done if a minimum time elapsed between the last call.
   */
  async function loadItems(params = {}, forceReload = false) {
    isLoading.value = true;
    await loadAction(forceReload, params)
      .then((data: T[]) => {
        items.value = data;
      })
      .catch((error: any) => {
        toast.add({
          severity: "error",
          summary: "Error loading data",
          detail: `An error occurred while loading data. ${error}`,
          life: 4000,
        });
      });
    isLoading.value = false;
  }

  return { isLoading, loadItems, items };
}

/**
 * Hook to load active and archived entities and stores the loaded items in a getters
 * @param {String} loadAction The load action for the active items
 * @param {String} itemGetters The getter name that contains the loaded active items
 * @param {String} loadArchiveAction The load action for the archived items
 * @param {String} archiveItemGetters The getter name that contains the loaded archived items
 * @returns viewOptions:String[] - Contains the two view options: Active and Archived
 * @returns selectedViewOptions:String - Stores the selected view options: Active or Archived
 * @returns isArchiveView:Boolean(Readonly) - Flag that indicates if the loaded items are the archived times
 * @returns isLoading:Boolean - Flag if items are loading
 * @returns items:Array - The loaded items. Depending on the selectedViewOptions:
 *            - If Active is selected, active items are provided.
 *            - If Archived is selected, archived items are provided.
 * @returns loadItems:function - Ref to the load items function
 *            - If Active is selected, the function loads the active items
 *            - If Archived is selected, the function loads the archived items
 */
export function useArchivedItemsLoader<
  T extends BaseApiEntity<TId>,
  TId extends string | number
>(
  loadAction: ApiSelectAllFunc,
  itemGetters: ComputedRef<T[]>,
  loadArchiveAction: ApiSelectAllFunc,
  archiveItemGetters: ComputedRef<T[]>
) {

  /**
   * Defining the available view options.
   */
   const viewOptions = ["Active", "Archived"];
   const selectedViewOptions = ref("Active");

  /**
   * Computed variable, that keeps a flag if return variables contains the archived or active items
   */
  const viewArchived = computed(() => {
    if (selectedViewOptions.value === "Archived") {
      return true;
    } else {
      return false;
    }
  });

  /**
   * Instance of useItemsLoader for the active items
   */
  const {
    isLoading: isActiveLoading,
    loadItems: loadActiveItems,
    items: activeItems,
  } = useItemsLoader(loadAction, itemGetters);

  /**
   * Instance of useItemsLoader for the archived items
   */
  const {
    isLoading: isArchiveLoading,
    loadItems: loadArchivedItems,
    items: archivedItems,
  } = useItemsLoader(loadArchiveAction, archiveItemGetters);

  /**
   * Loads the item by dispatching the load action.
   * Depending on the selected view option the archived of active items will be loaded
   * @param {Boolean} forceReload If true the backend is called anyway. If false, a reload from the
   *    backend will only be done if a minimum time elapsed between the last call.
   */
  function loadItems(forceReload = false) {
    if (viewArchived.value) {
      loadArchivedItems(forceReload);
    } else {
      loadActiveItems(forceReload);
    }
  }

  /**
   * Flag that items are loaded
   */
  const isLoading = computed(() => {
    return isActiveLoading.value || isArchiveLoading.value;
  });

  /**
   * Contains the loaded archived or active items. Depended on the selected view option.
   */
  const items = computed(() => {
    if (viewArchived.value) {
      return archivedItems.value;
    } else {
      return activeItems.value;
    }
  });


  watch(selectedViewOptions, () => {
    loadItems(false);
  });

  return {
    isLoading,
    loadItems,
    items,
    viewOptions,
    selectedViewOptions,
    isArchiveView: readonly(viewArchived),
  };
}
