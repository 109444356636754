<template>
    <div class="form-control" :class="{ invalid: isInvalid}">
        <label :for="idInput">{{ label }}</label>
        <slot></slot>
        <small
            v-if="validated && isInvalid"
          :id="idInput+'-help'"
          class="error-text full-width"
          >{{ errorMessage }}</small
        >
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        idInput: {
            type: String,
            required: true 
        },
        label: {            
            type: String,
            required: true 
        },
        validated: {            
            type: Boolean,
            required: false,
            default: false 
        },        
        isInvalid: {            
            type: Boolean,
            required: false,
            default: false 
        },
        errorMessage: {            
            type: String,
            required: false,            
            default: "" 
        },
    },
})
</script>

<style scoped>
@import  "../../../assets/css/form-styles.css";
</style>
