const PAYLOAD_COOKIE = "X-Token-Payload";
const REFRESHTOKEN_COOKIE = "X-RefreshToken";
const SIGNATURE_COOKIE = "X-Token-Signature";
const XSRF_COOKIE = "XSRF-TOKEN";

const USERNAME_CLAIM =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
const ROLES_CLAIM =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

const SALES_CLAIM = "Sales";
const SALES_MANAGER_CLAIM = "Sales-Manager";
const OPERATIONS_CLAIM = "Operations";
const ADMINISTRATIONS_CLAIM = "Administration";
const INTRANET_ADMIN_CLAIM = "Intranet-Administration";

const NEWS_MANAGER = "News-Manager";
const PROJECTS_MANAGER = "Projects-Manager";
const COMPANIES_MANAGER = "Companies-Manager";
const LICENSE_SUBSCRIPTION_VIEWER = "License-Subscription-Viewer";
const LICENSE_SUBSCRIPTION_MANAGER = "License-Subscription-Manager";
const PRODUCT_VERSIONS_MANAGER = "Product-Versions-Manager";

/**
 * Checks if the user is logged in
 * Condition: Payload Cookie contains a user name and has at least one role
 */
export function isLoggedIn(): boolean {
  // getting the payload cookie
  const jwtPayload = parsePayloadCookie();

  // no cookie or now payload means also not logged in
  if (!jwtPayload) {
    return false;
  }
  if (getUsername() !== "" && hasRole()) {
    return true;
  }
  return false;
}

/**
 * Checks if the JWT token is still valid (not expirered)
 */
export function isAccessTokenValid(): boolean {
  // getting the payload cookie
  const jwtPayload = parsePayloadCookie();

  // no cookie or now payload means also not logged in
  if (!jwtPayload || !jwtPayload["exp"]) {
    return false;
  }

  if (Date.now() < jwtPayload["exp"] * 1000) {
    return true;
  }

  return false;
}

/**
 * Reads the username out of the payload JWT cookie
 */
export function getUsername(): string {
  // getting the payload cookie
  const jwtPayload = parsePayloadCookie();

  if (jwtPayload && jwtPayload[USERNAME_CLAIM]) {
    return jwtPayload[USERNAME_CLAIM];
  }

  return "";
}

/**
 * Checks if the cookie contains the Sales Role
 */
export function isSales(): boolean {
  return (
    hasRoleClaim(SALES_CLAIM) ||
    hasRoleClaim(SALES_MANAGER_CLAIM) ||
    hasRoleClaim(INTRANET_ADMIN_CLAIM)
  );
}

/**
 * Checks if the cookie contains the Sales Role
 */
export function isSalesManager(): boolean {
  return (
    hasRoleClaim(SALES_MANAGER_CLAIM) || hasRoleClaim(INTRANET_ADMIN_CLAIM)
  );
}

/**
 * Checks if the cookie contains the Operations Role
 */
export function isOperationsManager(): boolean {
  return hasRoleClaim(OPERATIONS_CLAIM) || hasRoleClaim(INTRANET_ADMIN_CLAIM);
}

/**
 * Checks if the cookie contains the Administration Role
 */
export function isAdministrationsManager(): boolean {
  return (
    hasRoleClaim(ADMINISTRATIONS_CLAIM) || hasRoleClaim(INTRANET_ADMIN_CLAIM)
  );
}

/**
 * Checks if the cookie contains the RSG Admin role
 */
export function isIntranetAdminManager(): boolean {
  return hasRoleClaim(INTRANET_ADMIN_CLAIM);
}

/**
 * Checks if the cookie contains the News Manager
 * @returns 
 */
export function isNewsManager(): boolean {
  return hasRoleClaim(NEWS_MANAGER);
}

/**
 * Checks if the cookie contains at least one role
 */
function hasRole(): boolean {
  return (
    hasRoleClaim(SALES_CLAIM) ||
    hasRoleClaim(OPERATIONS_CLAIM) ||
    hasRoleClaim(ADMINISTRATIONS_CLAIM) ||
    hasRoleClaim(INTRANET_ADMIN_CLAIM) ||
    hasRoleClaim(NEWS_MANAGER)
  );
}

/**
 * Checks if the cookie for a specific role
 */
function hasRoleClaim(claimName: string): boolean {
  const jwtPayload = parsePayloadCookie();
  if (!jwtPayload || !jwtPayload[ROLES_CLAIM]) {
    return false;
  }
  const result = jwtPayload[ROLES_CLAIM].includes(claimName);
  return result;
}

/**
 * Parses the JWT payload cookie
 */
function parsePayloadCookie(): any | null {
  const cookie = getCookie(PAYLOAD_COOKIE);
  if (cookie) {
    const jwt = parseJwt(cookie);
    return jwt;
  }
  return null;
}

/**
 * Parse the JWT payload
 * @param {Object} token
 */
function parseJwt(token: string): any {
  const base64 = token.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

/**
 * Reads a cookie
 * @param {String} name
 */
function getCookie(name: string): string | null {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(";");
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
}

/**
 * Deletes the cookies by setting expiration into the past
 */
export function deleteCookies(): void {
  document.cookie = XSRF_COOKIE + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie =
    PAYLOAD_COOKIE + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie =
    REFRESHTOKEN_COOKIE + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie =
    SIGNATURE_COOKIE + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
}
