<template>
  <section class="news-card" :class="category">
    <header class="news-card-header" v-if="thumbnailUrl">
      <img :alt="thumbnailText" :src="thumbnailUrl" />
    </header>
    <article class="news-card-body">
      <header class="news-card-body-header">
        {{ title }}
      </header>
      <div class="news-card-content">
        <Scrollpanel style="width: 100%; height: 250px" class="custombar">
          <span v-html="content" class="linebreak"/>
          <a v-if="link" :href="link">{{ linkText }}</a>
        </Scrollpanel>
      </div>
      <footer class="news-card-footer">
        <small>{{ footerText }}</small>
      </footer>
    </article>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import Scrollpanel from "primevue/scrollpanel";

export default defineComponent({
  components: {
    Scrollpanel,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    content: {
      type: String,
      required: true,
      default: "",
    },
    author: {
      type: String,
      required: true,
      default: "",
    },
    category: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    linkText: {
      type: String,
      required: false,
    },
    thumbnailUrl: {
      type: String,
      required: false,
    },
    thumbnailText: {
      type: String,
      required: false,
    },
    publishDate: {
      type: Date,
      required: false,
    },
  },
  setup(props) {
    function formatDate(value: Date): string {
      if (value && value instanceof Date) {
        const day = value.getDate();
        const month = value.getMonth() + 1;
        const year = value.getFullYear();

        return `${year}-${month}-${day}`;
      }
      return "";
    }

    const footerText = computed(() => {
      let text = "";
      if (props.publishDate) {
        text = `Published on ${formatDate(props.publishDate)}`;
      }
      if (props.author) {
        text += `, by ${props.author}`;
      }

      return text;
    });

    return {
      footerText,
    };
  },
});
</script>

<style scoped>
.news-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  max-height: 550px;
}

.new-feature {
  box-shadow: 0 2px 1px -1px rgba(1, 255, 13, 0.5),
    0 1px 1px 0 rgba(1, 255, 13, 0.48), 0 1px 3px 0 rgba(1, 255, 13, 0.46) !important;
}

.new-content {
  box-shadow: 0 2px 1px -1px rgba(255, 153, 1, 0.86),
    0 1px 1px 0 rgba(255, 153, 1, 0.84), 0 1px 3px 0 rgba(255, 153, 1, 0.83) !important;
}

.update-content {
  box-shadow: 0 2px 1px -1px rgba(1, 26, 255, 0.86),
    0 1px 1px 0 rgba(1, 26, 255, 0.84), 0 1px 3px 0 rgba(1, 26, 255, 0.82) !important;
}

.news-card .news-card-header img {
  width: 100%;
  max-height: 150px;
}

.news-card .news-card-body {
  padding: 1rem;
  max-height: 400px;
}

.news-card .news-card-body-header {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.news-card .news-card-content {
  padding: 1rem 0;
}

.news-card .news-card-footer {
  padding: 1rem 0 0 0;
  height: 50;
  max-height: 50px;
}



</style>

