import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-between p-ai-center" }
const _hoisted_2 = { class: "p-d-flex p-jc-end p-ai-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_card_full_page = _resolveComponent("base-card-full-page")!

  return (_openBlock(), _createBlock(_component_base_card_full_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "additionalButtons"),
          (_ctx.showAddButton && !_ctx.link)
            ? (_openBlock(), _createBlock(_component_base_button, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addFunction())),
                icon: "pi pi-plus",
                label: _ctx.addButtonCaption,
                link: _ctx.link,
                to: _ctx.to,
                class: "p-ml-2"
              }, null, 8, ["label", "link", "to"]))
            : (_ctx.showAddButton && _ctx.link)
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 1,
                  "icon-link": "pi pi-plus",
                  label: _ctx.addButtonCaption,
                  link: "",
                  to: _ctx.to,
                  class: "p-ml-2"
                }, null, 8, ["label", "to"]))
              : _createCommentVNode("", true)
        ])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}