import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "news-card-header"
}
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { class: "news-card-body" }
const _hoisted_4 = { class: "news-card-body-header" }
const _hoisted_5 = { class: "news-card-content" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "news-card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Scrollpanel = _resolveComponent("Scrollpanel")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["news-card", _ctx.category])
  }, [
    (_ctx.thumbnailUrl)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createElementVNode("img", {
            alt: _ctx.thumbnailText,
            src: _ctx.thumbnailUrl
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("article", _hoisted_3, [
      _createElementVNode("header", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Scrollpanel, {
          style: {"width":"100%","height":"250px"},
          class: "custombar"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.content,
              class: "linebreak"
            }, null, 8, _hoisted_6),
            (_ctx.link)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.link
                }, _toDisplayString(_ctx.linkText), 9, _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("footer", _hoisted_8, [
        _createElementVNode("small", null, _toDisplayString(_ctx.footerText), 1)
      ])
    ])
  ], 2))
}