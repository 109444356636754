// This files contains common used types - The are not part of the domain model

/**
 * Definition of th UiMessage object. Can be used to show message in the Message component of primevue
 */
export interface UiMessage {
  id: number;
  severity: MessageSeverity;
  content: string;
}

/**
 * Defines the message severity level of message (shown in Message component of primevue)
 */
export enum MessageSeverity {
  SUCCESS = "success",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

export enum CallerType {
  NEW = 0,
  EDIT = 1,
  NEW_PRELOADED_DATA = 2,
}

/**
 * Base Class of an model object from the API
 */
export interface BaseApiEntity<TId extends number | string> {
  createApiSaveObject(): object;
  createApiUpdateObject(): object;
  getId(): TId;
}

/**
 * Definition of the Api Delete function call
 * Used to make sure that the correct store delete call is set in the delete hook by components
 */
export interface ApiDeleteFunc<TId extends number | string> {
  (itemId: TId): Promise<void>;
}

/**
 * Definition of the Api Archive function call
 * Used to make sure that the correct store archive call is set in the delete hook by components
 */
export interface ApiArchiveFunc<TId extends number | string> {
  (itemId: TId): Promise<void>;
}

/**
 * Definition of the Api select all function call
 * Used to make sure that the correct store select all call is set in the delete hook by components
 */
export interface ApiSelectAllFunc {
  (forceReload: boolean): Promise<void>;
}

/**
 * Definition of the Api select all with return value function call
 * Used to make sure that the correct store select all call  with return value is set in the delete hook by components
 */
export interface ApiSelectReturnAllFunc<
  T extends BaseApiEntity<TId>,
  TId extends number | string
> {
  (forceReload: boolean, params: object): Promise<T[]>;
}

/**
 * Definition of the Api Id is unique function call
 * Used to make sure that the correct store Id is unique call is set in the delete hook by components
 */
export interface ApiIsIdUniqueFunc<TId extends number | string> {
  (itemId: TId): Promise<boolean>;
}

/**
 * Definition of an key value object with optional number value
 */
export interface KeyValueType<TKey extends number | string> {
  key: TKey;
  value: string;
  numberValue?: number;
}

/**
 * Prototyp definition of a key value object
 */
export class KeyValueTypePrototype<TKey extends number | string>
  implements KeyValueType<TKey> {
  key: TKey;
  value: string;
  numberValue?: number;

  constructor(key: TKey, value: string, numberValue?: number) {
    this.key = key;
    this.value = value;
    this.numberValue = numberValue;
  }
}

/**
 * Definition of  the Viewport License Objects that is used to download a viewport.lic file
 * from the intranet
 */
export interface ViewportLicense {
  URL?: string;
  ApiKey?: string;
  SubscriptionKey?: string;
  SubscriptionDescription?: string;
  Licensee?: string;
  LicenseeName?: string;
  LicenseKey?: string;
}

export class ViewportLicensePrototyp implements ViewportLicense {
  URL?: string;
  ApiKey?: string;
  SubscriptionKey?: string;
  SubscriptionDescription?: string;
  Licensee?: string;
  LicenseeName?: string;
  LicenseKey?: string;

  constructor() {
    this.URL = "";
    this.ApiKey = "";
    this.SubscriptionKey = "";
    this.SubscriptionDescription = "";
    this.Licensee = "";
    this.LicenseeName = "";
    this.LicenseKey = "";
  }
}

