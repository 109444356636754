import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-field p-grid p-mb-1 p-ai-center" }
const _hoisted_2 = { class: "p-col-2 p-text-right" }
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  class: "p-col-10",
  style: {"height":"100%"}
}
const _hoisted_5 = { class: "p-col-10 p-offset-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createElementVNode("label", { for: _ctx.idInput }, _toDisplayString(_ctx.label), 9, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "error")
    ])
  ]))
}