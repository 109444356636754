import { useAuthStore } from "@/store/authStore";
import { computed } from "vue";

export default function useRoles() {
  const authStore = useAuthStore();

  const isCompanyManager = computed(() => authStore.isCompanyManager);

  const isDocumentNumberManager = computed(
    () => authStore.isDocumentNumberManager
  );

  const isProjectManager = computed(() => authStore.isProjectManager);

  const isSalesOpportunityManager = computed(
    () => authStore.isSalesOpportunityManager
  );

  const isLicenseSubscriptionManager = computed(
    () => authStore.isLicenseSubscriptionManager
  );

  const isDocTypDisciplineManager = computed(
    () => authStore.isDocTypDisciplineManager
  );

  const isProductVersionManager = computed(
    () => authStore.isProductVersionManager
  );

  const isNewsManager = computed(() => authStore.isNewsManager);

  const isUsersManager = computed(() => authStore.isUsersManager);

  return {
    isCompanyManager,
    isProjectManager,
    isSalesOpportunityManager,
    isLicenseSubscriptionManager,
    isDocTypDisciplineManager,
    isProductVersionManager,
    isDocumentNumberManager,
    isUsersManager,
    isNewsManager
  };
}
