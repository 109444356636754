<template>
  <hours-procedure-dialog
    @close="toggleHoursProcedureDialog"
    :open="showHoursProcedureDialog"
  ></hours-procedure-dialog>

  <base-two-cards>
    <template #default>
      <base-card style="margin-top:0">
        <iframe class="card" src="/quote.html"></iframe>
        <h2>Tools</h2>
        <section>
          <ul>
            <li>
              View <a href="http://h19.hetzners.radialts.com:3000/?orgId=1">Viewport Status Dashboard</a> on H19 (accessible only via VPN).
            </li>
            <li>
              Our <a href="http://www.viewport.ai/">Viewport.ai</a> website and
              the <a href="https://partners.radialsg.com/">Partner Portal</a>
            </li>
            <li>
              Log your hours in
              <a href="https://radialsg.odoo.com/web#cids=1&action=430&model=account.analytic.line&view_type=grid&menu_id=294" target="_blank">Odoo Timesheet</a
              >.
            </li>
            <li>
              View project tasks on the
              <a href="https://radialsg.odoo.com/web#menu_id=278&cids=1&action=402&model=project.project&view_type=kanban">Odoo Projects</a> kanban board.
            </li>
            <li>
              View your <a href="http://mail.radialsg.com/">e-mail</a> and
              <a href="https://calendar.google.com/">calendar</a>
              on Google Apps (generate your e-mail signature with the <router-link to="/SignatureGenerator"
                >Signature Generator</router-link
              >)
            </li>
            <li>Get <a href="https://dropbox.com/">Dropbox</a></li>
            <li>
              Plan across timezones with
              <a href="http://www.worldtimebuddy.com/time-converter"
                >WorldTimeBuddy</a
              >
            </li>
            <li>
              Quickly generate Markdown tables from Excel using
              <a
                href="https://thisdavej.com/copy-table-in-excel-and-paste-as-a-markdown-table/"
                >Excel-to-Markdown-Table</a
              >
            </li>
          </ul>
        </section>
      </base-card>
      <base-card>
        <h2>Sales and Marketing</h2>
        <ul>
          <li>
            <a href="https://radialsg.odoo.com/web#menu_id=223&cids=1&action=327&model=crm.lead&view_type=kanban"
              >Odoo CRM</a> and
            <a href="https://radialsg.odoo.com/web#menu_id=311&cids=1&action=468&model=sale.order&view_type=list"
              >Sales</a
            >
          </li>
          <li>
            <a href="https://5319830e.viewport.ai/"
              >5319830e Shared Demo Environment</a
            > (
              <a href="https://3319830e.viewport.ai">old</a> and 
              <a href="https://5329830e.viewport.ai/">nightly</a>
            )
          </li>
          <li>
            <a href="https://239a8313.viewport.ai/Demo/"
              >Radial SG Demo Environment</a
            >
          </li>
          <li>
            <a
              href="https://vps78-macs.macs.eu/maximo/webclient/login/login.jsp"
              >Macs Maximo demo environment</a
            >
          </li>
          <li>
            <a href="https://integrations.ultimo.com/"
              >Ultimo demo environment</a
            >
          </li>
        </ul>
      </base-card>
      <base-card>
        <h2>Development</h2>
        <ul>
          <li>
            View development and bugfix tasks on
            <a
              href="https://radialsg.atlassian.net/secure/RapidBoard.jspa?rapidView=5&projectKey=VP"
              >JIRA</a
            >
          </li>
          <li>
            View
            <a href="https://bitbucket.org/radialsg/viewport-git/commits/"
              >Viewport GIT</a
            >
            and
            <a
              href="https://radialsg.atlassian.net/secure/RapidBoard.jspa?rapidView=5&projectKey=VP"
              >the Jira backlog</a
            >
          </li>
          <li>
            View
            <a href="http://h07.hetzners.radialts.com:8111/favorite/projects?mode=builds"
              >TeamCity CI/CD</a
            >
            <ul>
                <li><img src="https://partners.viewport.ai/teamcity/buildType:id:Viewport_Master_Build/statusIcon.svg"> (master)</li>
                <li><img src="https://partners.viewport.ai/teamcity/buildType:id:ViewportDaily_ViewportDailyBuild53r2/statusIcon.svg"> (daily532)</li>
                <li><img src="https://partners.viewport.ai/teamcity/buildType:id:ViewportDaily_ViewportDailyBuild60/statusIcon.svg"> (daily-master)</li>
            </ul>
          </li>
        </ul>
      </base-card>
      <base-card>
        <h2>Operations and Support</h2>
        <ul>
          <li>
            <a href="https://trello.com/b/yTGUhccs/005-support-development-it"
              >Support Trello Board</a
            >
          </li>
          <li>
            <a href="https://stats.uptimerobot.com/EK5vMuW1l2">Uptime robot</a>
          </li>
          <li>
            <a
              href="http://support.radialsg.com/access/unauthenticated?return_to=http%3A%2F%2Fsupport.radialsg.com%2Fagent#login"
              >Zendesk</a
            >
          </li>
        </ul>
      </base-card>
      <base-card>
        <h2>Office / HR / Finance</h2>
        <ul>
          <li>
            <a href="https://trello.com/b/8Pl1UA6F/010-office-hr-finance"
              >Office / HR / Finance Trello Board</a
            >
          </li>
          <li><a href="https://app.minox.nl/">Minox Online</a></li>
        </ul>
      </base-card>
    </template>
    <template #card2>
      <news-carousel></news-carousel>
    </template>
  </base-two-cards>

  <div
    class="p-text-center p-text-light p-text-italic p-mb-2"
    style="color:gray;margin-top:1em;"
  >
    Intranet Frontend version: {{ appVersion }}
  </div>
</template>

<script>
import HoursProcedureDialog from "../../components/home/HoursProcedureDialog.vue";
import { ref } from "vue";
import NewsCarousel from '@/components/news/NewsCarousel.vue';

export default {
  components: {
    HoursProcedureDialog,
    NewsCarousel,
  },
  setup() {
    const showHoursProcedureDialog = ref(false);

    function toggleHoursProcedureDialog() {
      showHoursProcedureDialog.value = !showHoursProcedureDialog.value;
    }

    const appVersion = process.env.PACKAGE_VERSION;

    return {
      showHoursProcedureDialog,
      toggleHoursProcedureDialog,
      appVersion,
    };
  },
};
</script>

<style scoped>
iframe.card {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  width: 200px;
  float: right;
  margin-left: 2em;
  padding: 1em;
}
</style>
