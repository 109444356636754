import { defineStore } from "pinia";

import { BaseApiEntity } from "@/utils/commonTypes";

import {
  createObject,
  updateObject,
  loadObjects,
  loadObject,
  deleteObject,
  getHeaders,
  getBaseApiUrl,
  handleErrors
} from "@/utils/APICalls";
import { getUTCDateString } from "@/utils/commonUtils";

const ENDPOINT = "News";


export interface News extends BaseApiEntity<number> {
  id: number;
  title?: string;
  content?: string;
  author?: string;
  publishDate?: Date;
  unpublishDate?: Date;
  category?: string;
  publicationPlace?: string;
  link?: string;
  linkText?: string;
  thumbnailTitle?: string;
  thumbnailUrl?: string;
}

class NewsPrototyp implements News {
  id: number;
  title?: string;
  content?: string;
  author?: string;
  publishDate?: Date;
  unpublishDate?: Date;
  category?: string;
  publicationPlace?: string;
  link?: string;
  linkText?: string;
  thumbnailTitle?: string;
  thumbnailUrl?: string;

  constructor(id?: number) {
    if (id) {
      this.id = id;
    } else {
      this.id = -1;
    }
  }

  createApiSaveObject(): object {
    return {
      title: this.title,
      content: this.content,
      publishDate: this.publishDate ? getUTCDateString(this.publishDate) : this.publishDate,
      unpublishDate: this.unpublishDate? getUTCDateString(this.unpublishDate) : this.unpublishDate,
      category: this.category,
      publicationPlace: this.publicationPlace,
      link: this.link,
      linkText: this.linkText,
      thumbnailTitle: this.thumbnailTitle,
      thumbnailUrl: this.thumbnailUrl,  
      author: this.author
    };
  }

  createApiUpdateObject(): object {
    return {
      id: this.id,
      ...this.createApiSaveObject(),
    };
  }

  getId(): number {
    return this.id ? this.id : -1;
  }
}

export function getEmptyNews(): News {
  return new NewsPrototyp();
}

export function apiToObject(apiData: any): News | null {
  if (!apiData) {
    return null;
  }

  const news = new NewsPrototyp(apiData.id);

  news.title = apiData.title;
  news.content = apiData.content;
  news.publishDate = apiData.publishDate ? new Date(apiData.publishDate) :  apiData.publishDate;
  news.unpublishDate = apiData.unpublishDate ? new Date(apiData.unpublishDate) :  apiData.unpublishDate;
  news.category = apiData.category;
  news.publicationPlace = apiData.publicationPlace;
  news.link = apiData.link;
  news.linkText = apiData.linkText;
  news.thumbnailTitle = apiData.thumbnailTitle;
  news.thumbnailUrl = apiData.thumbnailUrl;
  
  news.author = apiData.author;

  return news;
}


import axios from "axios";

/****************************************************************
 * Store definition
 ***************************************************************/
export const useNewsStore = defineStore({
  id: "news",

  state: () => ({
    newsList: [] as Array<News>,
    currentNews: [] as Array<News>,
    lastFetch: null as null | number,
    categories: [
      { key: "new-feature", value: "New feature" },
      { key: "new-content", value: "New content" },
      { key: "update-content", value: "Content update" },
      { key: "support-documents", value: "Support documents" },
      { key: "vp-technical-overviews", value: "Viewport Technical overviews" },
      { key: "marketing-materials", value: "Marketing Materials" },
    ],
    publicationPlaces: ["Intranet", "Partner Portal"]
  }),

  getters: {
    /**
     * Checks, if the state should be updated with data from the api.
     * Within 60 seconds after the last api load the data would be loaded again
     * @returns
     */
    shouldUpdate(): boolean {
      if (!this.lastFetch) {
        return true;
      }
      const currentTimestamp = new Date().getTime();
      return (currentTimestamp - this.lastFetch) / 1000 > 60;
    },
  },

  actions: {
    /**
     * Creates a new News
     * @param data News object that shall be persisted
     */
    async create(data: News): Promise<void> {
      return await createObject(ENDPOINT, data.createApiSaveObject()).then(
        () => {
          this.newsList.push(data);
          return Promise.resolve();
        }
      );
    },
    /**
     * Deletes a news record by calling the endpoint
     * @param {object} context
     * @param {object} payload Contains attribute id that holds the News id
     */
    async delete(itemId: number): Promise<void> {
      return await deleteObject(ENDPOINT, itemId)
        .then(() => {
          const newsIndex = this.newsList.findIndex(
            (news: { id: number }) => news.id === itemId
          );
          this.newsList.splice(newsIndex, 1);

          return Promise.resolve();
        })
        .catch((error) => {
          throw error;
        });
    },
    /**
     * Loads a News by its Id
     * @param itemId The Id of the News to be loaded
     * @returns News object
     */
    async load(itemId: number): Promise<News> {
      return await loadObject(ENDPOINT, itemId).then((responseData: any) => {
        const discipline = apiToObject(responseData);
        if (discipline) {
          return new Promise((resolve) => {
            resolve(discipline);
          });
        }
        return Promise.reject("Could not convert to discipline object");
      });
    },
    /**
     * Loads all News from the api
     * @param forceReload If set to true the News are loaded from the api without taking the shouldUpdate flag in consideration
     * @returns
     */
    async loadAll(forceReload: boolean): Promise<void> {
      if (!forceReload && !this.shouldUpdate) {
        return;
      }
      return await loadObjects(ENDPOINT).then(
        (responseData: any) => {
          const newsList: News[] = [];
          for (const key in responseData) {
            const news = apiToObject(responseData[key]);
            if (news) {
              newsList.push(news);
            }
          }
          this.newsList = newsList;
          this.lastFetch = new Date().getTime();
        }
      );
    },
    /**
     * Updates a News object
     * @param data The News object to be updated
     */
    async update(data: News): Promise<void> {
      return await updateObject(
        ENDPOINT,
        data.id,
        data.createApiUpdateObject()
      ).then(() => {
        const newsIndex = this.newsList.findIndex(
          (news: { id: number }) => news.id === data.id
        );
        this.newsList[newsIndex] = data;
      });
    },

    async loadCurrentNews(): Promise<void> {
      const url = `${getBaseApiUrl()}${ENDPOINT}/GetCurrentNews`;

      return await axios
        .get(url, { headers: getHeaders()  })
        .then((response) => {

          const newsList: News[] = [];
          for (const key in response.data) {
            const news = apiToObject(response.data[key]);
            if (news) {
              newsList.push(news);
            }
          }
          this.currentNews = newsList; 

          return Promise.resolve();
        })
        .catch((error) => {
          const err = handleErrors(error);
          throw err;
        });
    }
  },
});
