import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-control", { invalid: _ctx.isInvalid}])
  }, [
    _createElementVNode("label", { for: _ctx.idInput }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.validated && _ctx.isInvalid)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          id: _ctx.idInput+'-help',
          class: "error-text full-width"
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}