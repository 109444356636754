<template>
  <base-card-full-page>
    <div class="p-d-flex p-jc-between p-ai-center">
      <h2>{{ title }}</h2>
      <div class="p-d-flex p-jc-end p-ai-center">
        <slot name="additionalButtons"> </slot>
        <!-- Button element -->
        <base-button
          v-if="showAddButton && !link"
          @click="addFunction()"
          icon="pi pi-plus"
          :label="addButtonCaption"
          :link="link"
          :to="to"
          class="p-ml-2"
        ></base-button>
        <!-- Link element -->
        <base-button
          v-else-if="showAddButton && link"
          icon-link="pi pi-plus"
          :label="addButtonCaption"
          link
          :to="to"
          class="p-ml-2"
        ></base-button>
      </div>
    </div>
    <slot>
      <!-- Here the table element -->
    </slot>
  </base-card-full-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    addButtonCaption: {
      type: String,
      required: true,
      default: "Add Item",
    },
    addFunction: {
      type: Function,
      required: false,
    },
    showAddButton: {
      type: Boolean,
      required: true,
      default: false,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
  },
});
</script>
